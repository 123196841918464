import React, { useContext } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box } from '@material-ui/core';
import Footer from '../layouts/Footer';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    boxLeft : {
        marginTop : theme.spacing(0),
        marginLeft : theme.spacing(1),
        alignItems: 'center'
    },
    boxRight: {
        marginTop : theme.spacing(10),
        marginLeft : theme.spacing(10)
    },
    bigHeading : {
        marginTop : theme.spacing(4),
        fontSize : 48,
        fontWeight : 600
    },
    printHeading : {
        marginTop : theme.spacing(3),
        color : '#005896',
        fontSize : 30,
        fontWeight : 600
    },
    bioHeading : {
        marginTop : theme.spacing(3),
        color : '#70ad45',
        fontSize : 30,
        fontWeight : 600
    }
  }));

export default function Home() {
    const classes = useStyles();
    
    const { userData } = useContext(UserContext);
    const history = useHistory();

    return(
        <>
        {
            (userData.token) ?
            history.push("/dashboard") :
            (<>
                <Container component="main" style={{backgroundImage: `url(${"/images/elni-bg.jpg"})`}}>
                    <CssBaseline />
                    <div className={classes.paper}>
                            <Box display="flex">
                                <Box p={4} className={classes.boxLeft}>
                                    <div className={classes.bigHeading}>Electronic Lab Notebook Index</div>
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box p={4} className={classes.boxLeft}>
                                    <img src="images\homepage_bg.jpg" width="auto" height="auto" alt="ELNI Logo" />
                                </Box>
                            </Box>
                    </div>
                </Container>
            </>)
        }
        </>
    );

   
}