import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import axios from "axios";
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#005896",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
    margin: theme.spacing(4)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    margin: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  paperList: {
    width: 300,
    height: 300,
    overflow: 'auto',
  },
}));

export default function ApproveMethod() {
  const classes = useStyles();
  
  const { userData } = useContext(UserContext);
  const history = useHistory();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
  }); 
  
  const entry = {};
  const [ methodName, setMethodName ] = useState();
  const [ methodDescription, setMethodDescription ] = useState();
  const [ methodCategory, setMethodCategory ] = useState("Other");

  const [ methodsDescription, setMethodsDescription ] = useState([]);

  const [ notification, setNotification ] = useState();
  const [ severity, setSeverity ] = useState();

  const [ submitDisabled, setSubmitDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ methodRequestTokenValid, setMethodRequestTokenValid ] = useState(true);
  const [ methodRequest, setMethodRequest ] = useState();
  const [ requestedBy, setRequestedBy ] = useState();
  const methodRequestToken = new URLSearchParams(useLocation().search).get('methodRequestToken');
  
  const [ openInfoDialog, setOpenInfoDialog ] = useState(false);

  useEffect(() => {
    const loadMethod = async () => {
            console.log(methodRequestToken);
            const methodRequestTokenValidRes = await axiosClient.post("methods/methodRequestTokenCheck", {methodRequestToken: methodRequestToken});
            if (!methodRequestTokenValidRes.data) {
                setMethodRequestTokenValid(false);
            } else {
                setMethodRequestTokenValid(true);
                const requestedByRes = await axiosClient.get("users/getUserById", { params: { userIdId: parseInt(methodRequestTokenValidRes.data.userId)}});
                setRequestedBy(requestedByRes.data);
                setMethodRequest(methodRequestTokenValidRes.data);
                setMethodName(methodRequestTokenValidRes.data.requestedMethod);
                setMethodDescription(methodRequestTokenValidRes.data.description);
                setMethodCategory(methodRequestTokenValidRes.data.category);    
                const getAllMethodsRes = await axiosClient.get("methods/getAll");
                setMethodsDescription(getAllMethodsRes.data);
            }
    }
    setLoading(true);
    loadMethod();
    setLoading(false);
  }, []);

  const validated = () => {
    
  }

  const clearFields = () => {
    setMethodName("");
  }

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time));
  }

  const approveMethod = async () => {
    setSubmitDisabled(true);
    setLoading(true);
    try {
        const approveMethodRes = await axiosClient.post("methods/approveMethod", 
        { id: methodRequest.id,
          methodName,
          methodDescription,
          methodCategory,
        });
        setSeverity("success");
        setNotification(approveMethodRes.data.responseMessage);
        sleep(5000).then(()=>{
        setLoading(false);
        history.push("/dashboard");
        });
    } catch (err) {
        setSubmitDisabled(false);
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const discardMethod = async () => {
    setSubmitDisabled(true);
    setLoading(true);
    try {
        const approveMethodRes = await axiosClient.post("methods/discardMethod", methodRequest);
        setSeverity("success");
        setNotification(approveMethodRes.data.responseMessage);
        sleep(5000).then(()=>{
        setLoading(false);
        history.push("/dashboard");
        });
    } catch (err) {
        setSubmitDisabled(false);
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const handleInfoClick = (e) => {
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  return (
    <>
    {  (!userData.token) ?
      (<div>You are not authorised to access this section. Please login.</div>) : (
      (!methodRequestTokenValid) ?
      (<div>Either a decision regarding the concerned method has already been made or the token has expired.</div>) : (
      <main /* style={{backgroundImage: `url(${"/images/elni-bg.jpg"})`}} */>
      <LoadingBackdrop open={loading} />
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}/*  style={{backgroundColor: "#ffffff"}} */>
        <Typography component="h1" variant="h5">
          Approve/Discard the following Method/Tag
        </Typography>
        <IconButton aria-label="delete" onClick={(e) => handleInfoClick()}>
          <InfoIcon fontSize='large'/>
        </IconButton>
        {notification && (
          <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table" >
                <TableBody>
                    <StyledTableRow key="requestedBy">
                        <StyledTableCell component="th" scope="row">
                            Requested By:
                        </StyledTableCell>
                        <StyledTableCell >{requestedBy ? requestedBy.firstname+" "+requestedBy.lastname : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="methodName">
                        <StyledTableCell component="th" scope="row">
                            Method/Tag Name:
                        </StyledTableCell>
                        <StyledTableCell >
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="method"
                          label="Method/Tag"
                          name="method"
                          value={methodName}
                          onChange={(e) => setMethodName(e.target.value)}
                        />
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="description">
                        <StyledTableCell component="th" scope="row">
                            Description:
                        </StyledTableCell>
                        <StyledTableCell >
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="description"
                          label="Description of Method/Tag"
                          name="method"
                          value={methodDescription}
                          onChange={(e) => setMethodDescription(e.target.value)}
                        />
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="category">
                        <StyledTableCell component="th" scope="row">
                            Category:
                        </StyledTableCell>
                        <StyledTableCell >
                            <FormControl variant="outlined"
                                margin="normal"
                                required
                                fullWidth 
                                className={classes.formControl}>
                                <InputLabel id="category_label">Category</InputLabel>
                                <Select
                                    labelId="Category"
                                    required
                                    fullWidth
                                    id="category"
                                    value={methodCategory}
                                    onChange={(e) => {setMethodCategory(e.target.value)}}
                                    >
                                    <MenuItem key={"Analytics"} value={"Analytics"}>Analytics</MenuItem>
                                    <MenuItem key={"Bioinks/Materials"} value={"Bioinks/Materials"}>Bioinks/Materials</MenuItem>
                                    <MenuItem key={"Cell Culture/Animals"} value={"Cell Culture/Animals"}>Cell Culture/Animals</MenuItem>
                                    <MenuItem key={"Printing/Methods"} value={"Printing/Methods"}>Printing/Methods</MenuItem>
                                    <MenuItem key={"Imaging/Modelling"} value={"Imaging/Modelling"}>Imaging/Modelling</MenuItem>
                                    <MenuItem key={"Other"} value={"Other"}>Other</MenuItem>
                                </Select>
                            </FormControl>
                          </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            </TableContainer>
          <Grid container spacing={3}>
          <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitDisabled}
            onClick={approveMethod}
          >
            Approve
          </Button>
          </Grid>
          <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disabled={submitDisabled}
            onClick={discardMethod}
          >
            Discard
          </Button>
          </Grid>
          </Grid>
          <Dialog
          open={openInfoDialog}
          onClose={handleCloseInfoDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">Methods/Tags Description</DialogTitle>
          <DialogContent>
          <h2>Analytics</h2>
          {methodsDescription.filter((method) => { return method.category === "Analytics" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <h2>Bioinks/Materials</h2>
          {methodsDescription.filter((method) => { return method.category === "Bioinks/Materials" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <h2>Cell Culture/Animals</h2>
          {methodsDescription.filter((method) => { return method.category === "Cell Culture/Animals" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <h2>Printing/Methods</h2>
          {methodsDescription.filter((method) => { return method.category === "Printing/Methods" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <h2>Imaging/Modelling</h2>
          {methodsDescription.filter((method) => { return method.category === "Imaging/Modelling" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseInfoDialog} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
      </Dialog>
      </div>
    </Container>
    </main>
    ))
  }
  </>);
}