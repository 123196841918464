import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import axios from "axios";
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
    margin: theme.spacing(4)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    margin: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  paperList: {
    width: 300,
    height: 300,
    overflow: 'auto',
  },
}));

export default function AddMethod() {
  const classes = useStyles();
  
  const { userData } = useContext(UserContext);
  const history = useHistory();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
  }); 

  const [ methodName, setMethodName ] = useState();
  const [ description, setDescription ] = useState();
  const [ methodCategory, setMethodCategory ] = useState("Other");

  const [ notification, setNotification ] = useState();
  const [ severity, setSeverity ] = useState();

  const [ submitDisabled, setSubmitDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const validated = () => {
    if (!methodName) {
      setSeverity("error");
      setNotification("Please fill in the Method/Tag name.");
      return false;
    }
    if (methodName.length < 3) {
      setSeverity("error");
      setNotification("Method name should be at least 3 characters long.");
      return false;
    }
    if (!description) {
      setSeverity("error");
      setNotification("Please fill in the Method/Tag description.");
      return false;
    }
    if (description.length < 5) {
      setSeverity("error");
      setNotification("Method description should be at least 5 characters long.");
      return false;
    }
    if (!methodName.startsWith("#")) {
      setSeverity("error");
      setNotification("Method name should begin with a HASHTAG(#).");
      return false;
    }
    return true;
  }

  const clearFields = () => {
    setMethodName("");
  }

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time));
  }

  const submit = async (e) => {
      e.preventDefault();

      if (validated()) {
        setSubmitDisabled(true);
        setLoading(true);
        try{
          const newMethod = { methodName, description, methodCategory };
          const requestMethodRes = await axiosClient.post("methods/requestMethod", newMethod);
          setSeverity("success");
          setNotification(requestMethodRes.data.responseMessage);
          e.target.reset();
          clearFields();
          userData.entries = userData.entries +1;
          sleep(5000).then(()=>{
            setLoading(false);
            history.push("/dashboard");
        });
        } catch (err) {
          setSubmitDisabled(false);
          setLoading(false);
          setSeverity("error");
          err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
      }      
  };

  return (
    <>
    {
      (!userData.token) ?
      (<div>You are not authorised to access this section. Please login.</div>) : (
      <main /* style={{backgroundImage: `url(${"/images/elni-bg.jpg"})`}} */>
      <LoadingBackdrop open={loading} />
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}/*  style={{backgroundColor: "#ffffff"}} */>
        <Typography component="h1" variant="h5">
          Request to add a new Method/Tag
        </Typography>
        {notification && (
          <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="method"
                label="Method/Tag"
                name="method"
                onChange={(e) => setMethodName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="description"
                label="Description of Method/Tag"
                name="method"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined"
              margin="normal"
              required
              fullWidth 
              className={classes.formControl}>
              <InputLabel id="category_label">Category</InputLabel>
              <Select
                  labelId="Category"
                  required
                  fullWidth
                  id="category"
                  value={methodCategory}
                  onChange={(e) => {setMethodCategory(e.target.value)}}
                  >
                  <MenuItem key={"Analytics"} value={"Analytics"}>Analytics</MenuItem>
                  <MenuItem key={"Bioinks/Materials"} value={"Bioinks/Materials"}>Bioinks/Materials</MenuItem>
                  <MenuItem key={"Cell Culture/Animals"} value={"Cell Culture/Animals"}>Cell Culture/Animals</MenuItem>
                  <MenuItem key={"Printing/Methods"} value={"Printing/Methods"}>Printing/Methods</MenuItem>
                  <MenuItem key={"Imaging/Modelling"} value={"Imaging/Modelling"}>Imaging/Modelling</MenuItem>
                  <MenuItem key={"Other"} value={"Other"}>Other</MenuItem>
              </Select>
          </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitDisabled}
          >
            Submit your request
          </Button>
          </Grid>
        </form>
      </div>
    </Container>
    </main>
    )
  }
  </>);
}