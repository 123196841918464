import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

export default function Footer() {
    const classes = useStyles();

    return (
        <>
        {/* Footer */}
        <footer className={classes.footer}>
            <Typography variant="h6" align="center" gutterBottom>
                TRR225 Electronic Lab Notebook Index
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                FMZ Würzburg.
            </Typography>
        </footer>
        {/* End footer */}
    </>
    )
}
