import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import axios from "axios";
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { Divider } from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { forEach } from 'underscore';


const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
    margin: theme.spacing(4)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    margin: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  paperList: {
    width: 250,
    height: 300,
    overflow: 'auto',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function MakeNewEntry() {
  const classes = useStyles();
  
  const { userData } = useContext(UserContext);
  const history = useHistory();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
  }); 

  const [ methodsList, setMethodsList ] = useState([]);
  const [ methodsDescription, setMethodsDescription ] = useState([]);
  
  const [ index, setIndex ] = useState(userData.initials + String(userData.entries).padStart(4, '0'));
  const [ project, setProject ] = useState();
  const [ hypothesis, setHypothesis ] = useState();
  const [ date, setDate ] = useState(new Date());
  const [ methods, setMethods ] = useState("");
  const [ elnReferences, setElnReferences ] = useState();

  const [ notification, setNotification ] = useState();
  const [ severity, setSeverity ] = useState();

  const [ submitDisabled, setSubmitDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(methodsList);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [ analyticsMethods, setAnalyticsMethods ] = useState([]);
  const [ bioinksMethods, setBioinksMethods ] = useState([]);
  const [ cellMethods, setCellMethods ] = useState([]);
  const [ imagingMethods, setImagingMethods ] = useState([]);
  const [ printingMethods, setPrintingMethods ] = useState([]);

  const [ methodsArray, setMethodsArray ] = useState([]);
  
  const [ openInfoDialog, setOpenInfoDialog ] = useState(false);

  let methodsAll = [];

  useEffect(() => {
    const loadMethods = async () => {
      try {
        const getAllMethodsRes = await axiosClient.get("methods/getAll");
        let availableMethods = [];
        for(let i = 0; i < getAllMethodsRes.data.length; i++) {
          availableMethods.push(" " + getAllMethodsRes.data[i].availableMethod);
        }
        setMethodsList(availableMethods);
        setLeft(availableMethods);
        setMethodsDescription(getAllMethodsRes.data);
        methodsAll = getAllMethodsRes.data;
        let analyticsBuild = [];
        let bioinksBuild = [];
        let cellBuild = [];
        let imagingBuild = [];
        let printingBuild = [];
        getAllMethodsRes.data.forEach(methodDetails => {
          methodDetails.checked = false;
          if (methodDetails.category === 'Analytics') {
            analyticsBuild.push(methodDetails);
          }
          if (methodDetails.category === 'Bioinks/Materials') {
            bioinksBuild.push(methodDetails);
          }
          if (methodDetails.category === 'Cell Culture/Animals') {
            cellBuild.push(methodDetails);
          }
          if (methodDetails.category === 'Imaging/Modelling') {
            imagingBuild.push(methodDetails);
          }
          if (methodDetails.category === 'Printing/Methods') {
            printingBuild.push(methodDetails);
          }
        });
        analyticsBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
        bioinksBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
        cellBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
        imagingBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
        printingBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
        setAnalyticsMethods(analyticsBuild);
        setBioinksMethods(bioinksBuild);
        setCellMethods(cellBuild);
        setImagingMethods(imagingBuild);
        setPrintingMethods(printingBuild);
      } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
      }
    };
    loadMethods();
  }, []);

  /* const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setMethods(right.concat(leftChecked).toString());
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setMethods(not(right, rightChecked).toString());
  }; */

  const handleCheck = (methodDetails) => {
    let methodsChecked = methodsArray;
    if (methodDetails.checked) {
      if (methodDetails.category === 'Analytics') {
        let analyticsBuild = analyticsMethods;
        analyticsBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = false;
          }
        });
        setAnalyticsMethods(analyticsBuild);
      }
      else if (methodDetails.category === 'Bioinks/Materials') {
        let bioBuild = bioinksMethods;
        bioBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = false;
          }
        });
        setBioinksMethods(bioBuild);
      }
      else if (methodDetails.category === 'Cell Culture/Animals') {
        let cellBuild = cellMethods;
        cellBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = false;
          }
        });
        setCellMethods(cellBuild);
      }
      else if (methodDetails.category === 'Imaging/Modelling') {
        let imagingBuild = imagingMethods;
        imagingBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = false;
          }
        });
        setImagingMethods(imagingBuild);
      }
      else if (methodDetails.category === 'Printing/Methods') {
        let printingBuild = printingMethods;
        printingBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = false;
          }
        });
        setPrintingMethods(printingBuild);
      }
      if (methodsChecked.includes(methodDetails.availableMethod)) {
        methodsChecked.splice(methodsChecked.indexOf(methodDetails.availableMethod), 1);
      }
    } else {
      if (methodDetails.category === 'Analytics') {
        let analyticsBuild = analyticsMethods;
        analyticsBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = true;
          }
        });
        setAnalyticsMethods(analyticsBuild);
      }
      else if (methodDetails.category === 'Bioinks/Materials') {
        let bioBuild = bioinksMethods;
        bioBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = true;
          }
        });
        setBioinksMethods(bioBuild);
      }
      else if (methodDetails.category === 'Cell Culture/Animals') {
        let cellBuild = cellMethods;
        cellBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = true;
          }
        });
        setCellMethods(cellBuild);
      }
      else if (methodDetails.category === 'Imaging/Modelling') {
        let imagingBuild = imagingMethods;
        imagingBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = true;
          }
        });
        setImagingMethods(imagingBuild);
      }
      else if (methodDetails.category === 'Printing/Methods') {
        let printingBuild = printingMethods;
        printingBuild.forEach(element => {
          if (element.availableMethod === methodDetails.availableMethod) {
            element.checked = true;
          }
        });
        setPrintingMethods(printingBuild);
      }
      methodsChecked.push(methodDetails.availableMethod);
    }
    setMethodsArray(methodsChecked);
    setMethods(methodsChecked.join(', '));
  }

  const methodList = (items) => (
    <Paper className={classes.paperList}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <Tooltip title={value.description} placement="bottom-start" arrow>
            <ListItem key={value} role="listitem" button onClick={(e) => handleCheck(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={value.checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.availableMethod}`} />
            </ListItem>
            </Tooltip>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );
  
  const validated = () => {
    if (!index || !project || !hypothesis || !date || !methods || !elnReferences) {
      setSeverity("error");
      setNotification("Please fill in all mandatory(*) fields.");
      return false;
    }
    if (project.length < 3) {
      setSeverity("error");
      setNotification("Please check the Project input.");
      return false;
    }
    return true;
  }

  const clearFields = () => {
    setIndex("");
    setProject("");
    setHypothesis("");
    setDate(new Date());
    setMethods("");
    setElnReferences("");
  }

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time));
  }

  const submit = async (e) => {
      e.preventDefault();

      if (validated()) {
        setSubmitDisabled(true);
        setLoading(true);
        try{
          const newEntry = { project, hypothesis, methods, elnReferences };
          const createEntryRes = await axiosClient.post("entry/makeEntry", newEntry);
          setSeverity("success");
          setNotification(createEntryRes.data.responseMessage);
          e.target.reset();
          clearFields();
          userData.entries = userData.entries +1;
          sleep(5000).then(()=>{
            setLoading(false);
            history.push("/dashboard");
        });
        } catch (err) {
          setSubmitDisabled(false);
          setLoading(false);
          setSeverity("error");
          err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
      }      
  };

  const handleInfoClick = (e) => {
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  return (
    <>
    {
      (!userData.token) ?
      (<div>You are not authorised to access this section. Please login.</div>) : (
      <main /* style={{backgroundImage: `url(${"/images/elni-bg.jpg"})`}} */>
      <LoadingBackdrop open={loading} />
    <Container component="main" maxWidth="xl">
      <CssBaseline />
      <div className={classes.paper}/*  style={{backgroundColor: "#ffffff"}} */>
        <Typography component="h1" variant="h5">
          Make a new entry
        </Typography>
        {notification && (
          <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                disabled
                value={index}
                helperText="Unique ID of your new Entry."
                id="index"
                name="index"
                autoComplete="index"
                onChange={(e) => setIndex(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="project"
                label="Project"
                name="project"
                autoComplete="project"
                onChange={(e) => setProject(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                multiline
                id="hypothesis"
                label="Project Hypothesis"
                name="hypothesis"
                autoComplete="hypothesis"
                onChange={(e) => setHypothesis(e.target.value)}
              />
            </Grid>
            {/* <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className={classes.root}
            > 
              <Grid item><h4>Available Methods
              <IconButton aria-label="delete" onClick={(e) => handleInfoClick()}>
                <InfoIcon fontSize='large'/>
              </IconButton></h4>
              {customList(left)}
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item><h4>Selected Methods
                <IconButton aria-label="delete" onClick={(e) => handleInfoClick()}>
                <InfoIcon fontSize='large'/>
              </IconButton></h4>
              {customList(right)}</Grid>
            </Grid> */}
            <Grid container>
              <Grid item><h4>Analytics</h4>
                {methodList(analyticsMethods)}
              </Grid>
              <Grid item><h4>Bioinks/Materials</h4>
                {methodList(bioinksMethods)}
              </Grid>
              <Grid item><h4>Cell Culture/Animals</h4>
                {methodList(cellMethods)}
              </Grid>
              <Grid item><h4>Imaging/Modelling</h4>
                {methodList(imagingMethods)}
              </Grid>
              <Grid item><h4>Printing/Methods</h4>
                {methodList(printingMethods)}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                disabled
                value={methods}
                helperText="Methods/Tags selected from above will be listed here."
                id="methods"
                name="methods"
                autoComplete="methods"
                onChange={(e) => setMethods(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="elnReferences"
                label="ELN Entry(LabFolder) or Experiment(SciNote)"
                name="elnReferences"
                autoComplete="elnReferences"
                onChange={(e) => setElnReferences(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitDisabled}
          >
            Submit this Entry
          </Button>
          </Grid>
        </form>
        <Dialog
          open={openInfoDialog}
          onClose={handleCloseInfoDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">Methods/Tags Description</DialogTitle>
          <DialogContent>
          <h2>Analytics</h2>
          {methodsDescription.filter((method) => { return method.category === "Analytics" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <Divider />
          <h2>Bioinks/Materials</h2>
          {methodsDescription.filter((method) => { return method.category === "Bioinks/Materials" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <Divider />
          <h2>Cell Culture/Animals</h2>
          {methodsDescription.filter((method) => { return method.category === "Cell Culture/Animals" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <Divider />
          <h2>Printing/Methods</h2>
          {methodsDescription.filter((method) => { return method.category === "Printing/Methods" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <Divider />
          <h2>Imaging/Modelling</h2>
          {methodsDescription.filter((method) => { return method.category === "Imaging/Modelling" }).map(method => (
            <Typography>
              {method.availableMethod} - {method.description}
            </Typography>
          ))}
          <Divider />
          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseInfoDialog} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
      </Dialog>
      </div>
    </Container>
    </main>
    )
  }
  </>);
}