import React, { useContext, useState, useEffect, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import {DropzoneArea} from 'material-ui-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Switch from '@material-ui/core/Switch';import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Link from '@material-ui/core/Link';
import LinkIcon from '@material-ui/icons/Link';

import NotificationSnackbar from "../../feedback/NotificationSnackbar";
import LoadingBackdrop from '../../feedback/LoadingBackdrop';

import UserContext from "../../../context/UserContext";
import { useHistory } from 'react-router-dom';
import Footer from '../../layouts/Footer';
import axios from "axios";
import { forEach } from 'underscore';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightBold,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    editModeSwitch: {
      float: 'right'
    }
}));

export default function Stipends() {
    const classes = useStyles();
    
    const { userData } = useContext(UserContext);
    const history = useHistory();

    const axiosClient = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
      headers: {
          "x-auth-token" : userData.token
      }
    });

    useEffect(() => {
      loadDocuments();
      loadForms();
      loadLinks();
  }, []);

  const loadDocuments = async () => {
      try {
          setLoading(true);
          const documentsRes = await axiosClient.get("stipendsInternalDocuments/getDocuments")
          setDocuments(documentsRes.data);
          setLoading(false);
      } catch (err) {
          setLoading(false);
          setSeverity("error");
          err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
      }
  };

  const loadForms = async () => {
    try {
        setLoading(true);
        const formsRes = await axiosClient.get("stipendsInternalDocuments/getForms")
        setForms(formsRes.data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
};

  const loadLinks = async () => {
    try {
        setLoading(true);
        const linksRes = await axiosClient.get("stipendsInternalDocuments/getLinks")
        setLinks(linksRes.data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
};

    const [ editMode, setEditMode ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ documents, setDocuments ] = useState([]);
    const [ forms, setForms ] = useState([]);
    const [ links, setLinks ] = useState([]);

    const [ docName, setDocName ] = useState("");
    const [ docFile, setDocFile ] = useState([]);
    const [ docFilename, setDocFilename ] = useState("");

    const [ formName, setFormName ] = useState("");
    const [ formFile, setFormFile ] = useState([]);
    const [ formFilename, setFormFilename ] = useState("");

    const [ linkName, setLinkName ] = useState("");
    const [ linkUrl, setLinkUrl ] = useState("");
    
    const [ docName_disabled, setDocName_disabled ] = useState(false);
    const [ formName_disabled, setFormName_disabled ] = useState(false);
    const [ linkName_disabled, setLinkName_disabled ] = useState(false);

    const [ openDocumentDialog, setOpenDocumentDialog ] = useState(false);
    const [ documentDialogTitle, setDocumentDialogTitle ] = useState("Upload Document");

    const [ openFormDialog, setOpenFormDialog ] = useState(false);
    const [ formDialogTitle, setFormDialogTitle ] = useState("Upload Forms/Templates");

    const [ openLinkDialog, setOpenLinkDialog ] = useState(false);
    const [ linkDialogTitle, setLinkDialogTitle ] = useState("Create Link");

    const [ openDeleteDocumentDialog, setOpenDeleteDocumentDialog ] = useState(false);
    const [ deleteDocumentDialogTitle, setDeleteDocumentDialogTitle ] = useState("Delete");
    const [ deleteDocumentName, setDeleteDocumentName ] = useState("");

    const [ openDeleteFormDialog, setOpenDeleteFormDialog ] = useState(false);
    const [ deleteFormDialogTitle, setDeleteFormDialogTitle ] = useState("Delete");
    const [ deleteFormName, setDeleteFormName ] = useState("");

    const [ openDeleteLinkDialog, setOpenDeleteLinkDialog ] = useState(false);
    const [ deleteLinkDialogTitle, setDeleteLinkDialogTitle ] = useState("Delete");
    const [ deleteLinkId, setDeleteLinkId ] = useState();


    // TODO: Check if same name exists in all documents, forms
    const handleDocDataChange = async (newFiles) => {
      setLoading(true);
      documents.forEach(document => {
        if (document.document_name.toUpperCase() === docName.toUpperCase()) {
          setSeverity("error");
          setNotification("Please change the document name. A document with same name already Exists.");
          sleep(5000).then(()=>{
              history.push("/internalDocuments");
          });
        }
      });
      const file = newFiles[0];
      if (file) {
          const newFile=new File([file], docName.replace(/\s+/g, '_')+"."+file.name.split('.').pop()); 
          setDocFile([newFile]);
          setDocFilename(docName.replace(/\s+/g, '_')+"."+file.name.split('.').pop())
          setDocName_disabled(true);
      }
      setLoading(false);
  };

  const handleDocDataDelete = (deletedFile) => {
      setDocName_disabled(false);
      setDocFile([]);
      setDocName("");
  };

  const clearDocFields = () => {
    setDocName_disabled(false);
    setDocFile([]);
    setDocName("");
};

const handleFormDataChange = async (newFiles) => {
  setLoading(true);
  forms.forEach(form => {
    if (form.form_name.toUpperCase() === formName.toUpperCase()) {
      setSeverity("error");
      setNotification("Please change the form name. A form with same name already Exists.");
      sleep(5000).then(()=>{
          history.push("/internalDocuments");
      });
    }
  });
  const file = newFiles[0];
  if (file) {
      const newFile=new File([file], formName.replace(/\s+/g, '_')+"."+file.name.split('.').pop()); 
      setFormFile([newFile]);
      setFormFilename(formName.replace(/\s+/g, '_')+"."+file.name.split('.').pop())
      setFormName_disabled(true);
  }
  setLoading(false);
};

const handleFormDataDelete = (deletedFile) => {
  setFormName_disabled(false);
  setFormFile([]);
  setFormName("");
};

const clearFormFields = () => {
setFormName_disabled(false);
setFormFile([]);
setFormName("");
};

const clearLinkFields = () => {
  setLinkName_disabled(false);
  setLinkUrl("");
  setLinkName("");
};

  const handleCloseDocumentDialog = () => {
    setOpenDocumentDialog(false);
    setDocName_disabled(false);
};

const handleCloseFormDialog = () => {
  setOpenFormDialog(false);
  setFormName_disabled(false);
};

const handleCloseLinkDialog = () => {
  setOpenLinkDialog(false);
};

const handleCloseDeleteDocumentDialog = () => {
  setOpenDeleteDocumentDialog(false);
}

const handleCloseDeleteFormDialog = () => {
  setOpenDeleteFormDialog(false);
}

const handleCloseDeleteLinkDialog = () => {
  setOpenDeleteLinkDialog(false);
}

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time));
};

  const uploadDocument = async (e) => {
    setOpenDocumentDialog(false);
    setLoading(true);
    try {
        const documentUpload = new FormData();
        if(docFile[0]) {documentUpload.append("stipendsDocument", docFile[0]);}
        documentUpload.append("document_name", docName);
        documentUpload.append("document_file", docFilename);
        const documentUploadRes = await axiosClient.post("stipendsInternalDocuments/documentUpload", documentUpload);
        clearDocFields();
        setLoading(false);
        setSeverity("success");
        setNotification(documentUploadRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const uploadForm = async (e) => {
    setOpenFormDialog(false);
    setLoading(true);
    try {
        const formUpload = new FormData();
        if(docFile[0]) {formUpload.append("stipendsForm", docFile[0]);}
        formUpload.append("form_name", docName);
        formUpload.append("form_file", docFilename);
        const formUploadRes = await axiosClient.post("stipendsInternalDocuments/formUpload", formUpload);
        clearDocFields();
        setLoading(false);
        setSeverity("success");
        setNotification(formUploadRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };


  const createLink = async () => {
    setOpenLinkDialog(false);
    setLoading(true);
    try {
        const createLinkRes = await axiosClient.post("stipendsInternalDocuments/createLink", {link_name:linkName, link_url:linkUrl});
        clearLinkFields();
        setLoading(false);
        setSeverity("success");
        setNotification(createLinkRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  }

  const handleDeleteDocumentClick = (e, doc) => {
    setDeleteDocumentDialogTitle("Are you sure you want to delete the document: " + doc.document_name+ "?");
    setDeleteDocumentName(doc.document_name);
    setOpenDeleteDocumentDialog(true);
  }

  const handleDeleteFormClick = (e, doc) => {
    setDeleteFormDialogTitle("Are you sure you want to delete the document: " + doc.form_name+ "?");
    setDeleteFormName(doc.form_name);
    setOpenDeleteFormDialog(true);
  }
  
  const handleDeleteLinkClick = (e, link) => {
    setDeleteLinkDialogTitle("Are you sure you want to delete the link: " + link.link_name+ "?");
    setDeleteLinkId(link.id);
    setOpenDeleteLinkDialog(true);
  }

  const deleteDocumentConfirm = async (e) => {
    try {
        setOpenDeleteDocumentDialog(false);
        const documentDeleteRes = await axiosClient.delete("stipendsInternalDocuments/deleteDocument", {params: {document_name: deleteDocumentName}});
        setSeverity("success");
        setNotification(documentDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  const deleteFormConfirm = async (e) => {
    try {
        setOpenDeleteFormDialog(false);
        const formDeleteRes = await axiosClient.delete("stipendsInternalDocuments/deleteForm", {params: {form_name: deleteFormName}});
        setSeverity("success");
        setNotification(formDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  const deleteLinkConfirm = async (e) => {
    try {
        setOpenDeleteLinkDialog(false);
        const linkDeleteRes = await axiosClient.delete("stipendsInternalDocuments/deleteLink", {params: {link_id: deleteLinkId}});
        setSeverity("success");
        setNotification(linkDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  let compareRef = useRef();

    return (
      <>
      {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
      (
    <React.Fragment>
      <CssBaseline />
      <main>
      
        <Container  maxWidth="lg">
          <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
              TRR225 Stipends, Awards, Proposals
              {userData.role === 'admin' ? (
                <Typography component="h6" variant='h6' align='right' color='textPrimary'>
                  Toggle Edit Mode
                  <Switch
                    checked={editMode}
                    onChange={(e) => setEditMode(!editMode)}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    className={classes.editModeSwitch}
                  />
                </Typography>
              ) : (<div></div>)}
          </Typography>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Documents {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={(e) => setOpenDocumentDialog(true)}
                >
                  Upload Document
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                    {documents.map(document => (
                        <ListItem key={document.document_name}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Link href={process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/internaldocuments/stipendsdocs/"+document.document_file}>
                                            {document.document_name}
                                        </Link>}
                            />
                            <ListItemSecondaryAction>
                                {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteDocument(document.document_name)}>
                                    Edit
                                </Button> */}
                                {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteDocumentClick(e, document)}>
                                    <DeleteForeverIcon />
                                </IconButton>) : (<div></div>)}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Forms/Templates {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={(e) => setOpenFormDialog(true)}
                >
                  Upload Form
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                    {forms.map(form => (
                        <ListItem key={form.form_name}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Link href={process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/internaldocuments/stipendsdocs/"+form.form_file}>
                                            {form.form_name}
                                        </Link>}
                            />
                            <ListItemSecondaryAction>
                                {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteForm(form.form_name)}>
                                    Edit
                                </Button> */}
                                {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteFormClick(e, form)}>
                                    <DeleteForeverIcon />
                                </IconButton>) : (<div></div>)}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Links {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<LinkIcon />}
                  onClick={(e) => setOpenLinkDialog(true)}
                >
                  Create Link
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {links.map(link => (
                    <ListItem key={link.link_name}>
                        <ListItemIcon>
                          <LinkIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Link href={link.link_url}>
                                        {link.link_name}
                                    </Link>}
                        />
                        <ListItemSecondaryAction>
                            {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteDocument(document.document_name)}>
                                Edit
                            </Button> */}
                            {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteLinkClick(e, link)}>
                                <DeleteForeverIcon />
                            </IconButton>) : (<div></div>)}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Container>
        <Dialog
          open={openDocumentDialog}
          onClose={handleCloseDocumentDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{documentDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Upload.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={docName_disabled}
            value={docName}
            fullWidth
            id="doc_name"
            label="Document Name"
            name="doc_name"
            onChange={(e) => setDocName(e.target.value)}
          />
          <DropzoneArea
            acceptedFiles={[]}
            showFileNamesInPreview={true}
            filesLimit={1}
            onChange={handleDocDataChange}
            onDelete={handleDocDataDelete}
            useChipsForPreview={true}
            dropzoneText="Click to Browse or Drag a file here"
            showAlerts={false}
          />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={uploadDocument}>
              Upload
          </Button>
          <Button onClick={handleCloseDocumentDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openFormDialog}
          onClose={handleCloseFormDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Upload.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={docName_disabled}
            value={docName}
            fullWidth
            id="doc_name"
            label="Form Name"
            name="doc_name"
            onChange={(e) => setDocName(e.target.value)}
          />
          <DropzoneArea
            acceptedFiles={[]}
            showFileNamesInPreview={true}
            filesLimit={1}
            onChange={handleDocDataChange}
            onDelete={handleDocDataDelete}
            useChipsForPreview={true}
            dropzoneText="Click to Browse or Drag a file here"
            showAlerts={false}
          />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={uploadForm}>
              Upload
          </Button>
          <Button onClick={handleCloseFormDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openLinkDialog}
          onClose={handleCloseLinkDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{linkDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Create Link.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            value={linkName}
            fullWidth
            id="link_name"
            label="Link Name"
            name="link_name"
            onChange={(e) => setLinkName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            value={linkUrl}
            fullWidth
            id="link_url"
            label="Link URL"
            name="link_url"
            onChange={(e) => setLinkUrl(e.target.value)}
          />
          </DialogContent>
          <DialogActions>
          <Button onClick={createLink} color="primary" type="submit">
              Create Link
          </Button>
          <Button onClick={handleCloseLinkDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteDocumentDialog}
          onClose={handleCloseDeleteDocumentDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteDocumentDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteDocumentConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteDocumentDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteFormDialog}
          onClose={handleCloseDeleteFormDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteFormDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteFormConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteFormDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteLinkDialog}
          onClose={handleCloseDeleteLinkDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteLinkDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteLinkConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteLinkDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      </main>
      {notification && (
                <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
            )}
            <LoadingBackdrop open={loading} />
    </React.Fragment>
    )
    }
    </>
  )
}
