import React, { useState, useContext, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { InputLabel } from '@material-ui/core';

import axios from "axios";
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function UserEdit() {
  const classes = useStyles();
  
  const { userData } = useContext(UserContext);

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
  }); 

  const [ firstname, setFirstname ] = useState();
  const [ lastname, setLastname ] = useState();
  const [ initials, setInitials ] = useState();
  const [ email, setEmail ] = useState();
  const [ institute, setInstitute ] = useState();
  const [ subProject, setSubProject ] = useState();
  const [ department, setDepartment ] = useState();
  const [ responsiblePi, setResponsiblePi ] = useState();
  const [ username, setUsername ] = useState();
  const [ password, setPassword ] = useState();
  const [ confirmPassword, setConfirmPassword ] = useState();

  const [ notification, setNotification ] = useState();
  const [ severity, setSeverity ] = useState();

  const [ submitDisabled, setSubmitDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ user, setUser ] = useState();

  useEffect(() => {
    const loadUser = async () => {
         if(!user && localStorage.getItem("edit_user_id")){
            try {
                const userRes = await axiosClient.get("/users/getUserForEdit", {params: {id: localStorage.getItem("edit_user_id")}});
                setUser(userRes.data);
                setFirstname(userRes.data.firstname);
                setLastname(userRes.data.lastname);
                setInitials(userRes.data.initials);
                setEmail(userRes.data.email);
                setInstitute(userRes.data.institute);
                setSubProject(userRes.data.subProject);
                setDepartment(userRes.data.department);
                setResponsiblePi(userRes.data.responsiblePi);
                setUsername(userRes.data.username);
            } catch (error) {
                
            }
         }
    }
    setLoading(true);
    loadUser();
    setLoading(false);
  }, []);


  const validated = () => {
    if (!firstname || !lastname || !initials || !email || !subProject || !institute || !department || !responsiblePi) {
      setSeverity("error");
      setNotification("Please fill in all fields.");
      return false;
    }

    if (!(email.includes("@",2)) || !(email.includes(".",4))) {
      setSeverity("error");
      setNotification("Please check the email address.");
      return false;
    }

    if (responsiblePi) {
      if (!(responsiblePi.includes("@",2)) || !(responsiblePi.includes(".",4))) {
        setSeverity("error");
        setNotification("Please check the responsible PI's email address.");
        return false;
      }
    }

    return true;
  }

  const clearFields = () => {
    setFirstname("");
    setLastname("");
    setInitials("");
    setEmail("");
    setInstitute("");
    setDepartment("");
    setResponsiblePi("");
    setUsername("");
    setSubProject("");
  }

  const submit = async (e) => {
      e.preventDefault();

      if (validated()) {
        setSubmitDisabled(true);
        setLoading(true);
        try{
          const newUser = { id: user.userId, firstname, lastname, initials, email, subProject, institute, department, responsiblePi };
          const updateUserRes = await axiosClient.put("users/updateUser", newUser);
          setLoading(false);
          setSeverity("success");
          setNotification(updateUserRes.data.responseMessage);
          e.target.reset();
          clearFields();
        } catch (err) {
          setSubmitDisabled(false);
          setLoading(false);
          setSeverity("error");
          err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
      }      
  };

  return (
    <>
    {
      (!userData.token || userData.role !== "admin") ?
      (<div>You are not authorised to access this section. Please login as "admin".</div>) : (
      <>
      <LoadingBackdrop open={loading} />
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <PersonAddIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Edit User
        </Typography>
        {notification && (
          <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstname"
                variant="outlined"
                required
                fullWidth
                id="firstname"
                label="First Name"
                autoFocus
                value={firstname}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastname"
                label="Last Name"
                autoFocus
                name="lastname"
                autoComplete="lname"
                value={lastname}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                disabled
                id="initials"
                label="Initials"
                name="initials"
                autoComplete="initials"
                value={initials}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setInitials(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="institute"
                label="Institute"
                name="institute"
                autoComplete="institute"
                value={institute}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setInstitute(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="subProject"
                label="TRR225 Project"
                name="subProject"
                autoComplete="subProject"
                value={subProject}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setSubProject(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="department"
                label="Department"
                name="department"
                autoComplete="department"
                value={department}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setDepartment(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="responsiblePi"
                label="Email of responsible PI"
                name="responsiblePi"
                autoComplete="email"
                value={responsiblePi}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setResponsiblePi(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                disabled
                name="displayName"
                label="Username"
                type="username"
                id="displayName"
                autoComplete="username"
                value={username}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>            
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitDisabled}
          >
            Update User Data
          </Button>
          
        </form>
      </div>
    </Container>
    </>
    )
  }
  </>);
}