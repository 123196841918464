import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _, { methods } from 'underscore';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import LabelIcon from '@material-ui/icons/Label';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { TextField } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

const sortByInputs = [
    {
        value: 'firstname',
        label: 'Firstname'
    },
    {
        value: 'lastname',
        label: 'Lastname'
    },
    {
        value: 'subProject',
        label: 'TRR225 Project'
    },
    {
        value: 'department',
        label: 'Department'
    },
    {
        value: 'institute',
        label: 'Institute'
    },
];

export default function ViewUsers() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ allUsers, setAllUsers ] = useState([]);
    const [ users, setUsers ] = useState([]);
    const [ usersLoaded, setUsersLoaded ] = useState(false);
    /* let searchKey = ""; */
    const [ sortBy, setSortBy ] = useState("");
  
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        loadAllUsers();
    }, []);

    const loadAllUsers = async () => {
        try {
            setLoading(true);
            const usersRes = await axiosClient.get("users/getAllUsers");
            setUsers(usersRes.data);
            setAllUsers(usersRes.data);
            setUsersLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    };

    const handleEditClick = (e, mId) => {
        localStorage.setItem("edit_user_id", mId);
        history.push("/userEdit");
    };

    let compareRef = useRef();

    const filterUsers = (searchKey) => {
        const results = [];
        allUsers.forEach(user => {
            if (user.firstname.toUpperCase().includes(searchKey.toUpperCase()) || user.lastname.toUpperCase().includes(searchKey.toUpperCase())
             || user.institute.toUpperCase().includes(searchKey.toUpperCase()) || user.subProject.toUpperCase().includes(searchKey.toUpperCase())
             || user.email.toUpperCase().includes(searchKey.toUpperCase()) || user.department.toUpperCase().includes(searchKey.toUpperCase())) {
                results.push(user);
            }
        });
        setUsers(results);
    };
    
    const sortUsers = (sortKey) => {
        setUsers(_.sortBy(users, sortKey));
    };
    
    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <React.Fragment>
            <CssBaseline />
            <main>
                <LoadingBackdrop open={loading} />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                <Container maxWidth="lg">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    User Management
                    </Typography>
                </Container>
                </div>
                <Container maxWidth="md">
                    {(usersLoaded) ? 
                        (
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="users_search"
                                    label="Search Users"
                                    name="users_search"
                                    onChange={(e) => {/* searchKey=e.target.value; */ filterUsers(e.target.value);}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="users_sort"
                                    name="users_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortUsers(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        ) : (<div></div>)
                    }
                    {(users.length < 1) ? (<h5>No results</h5>) : (<div></div>)}
                <List>
                    {users.map(user => (
                        <ListItem key={user.availableUser}>
                            <ListItemIcon>
                                <LabelIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={user.firstname + " " + user.lastname}
                                secondary={`TRR225 Project: ${user.subProject} || Email: ${user.email} || Initials: ${user.initials} || Username: ${user.elnUsername}
                                 || Institute: ${user.institute} || Department: ${user.department} || Responsible PI: ${user.responsiblePi}`} 
                            />
                            <ListItemSecondaryAction>
                                <Button ref={compareRef} color="secondary" variant="contained" onClick={(e) => handleEditClick(e, user.id)}>
                                    Edit
                                </Button>
                                {/* <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteClick(e, entry)}>
                                    <DeleteForeverIcon />
                                </IconButton> */}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                </Container>
            </main>
        </React.Fragment>
        )
        }
        </>
    );
}
