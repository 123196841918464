import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import axios from "axios";
import Header from "./components/layouts/Header";
import Home from './components/pages/Home';
import Login from './components/auth/Login';
import CreateUser from './components/auth/CreateUser';
import UserContext from './context/UserContext';
import Dashboard from "./components/pages/Dashboard";
import CreateNewPassword from "./components/auth/CreateNewPassword";
import ChangePassword from "./components/auth/ChangePassword";
import MakeNewEntry from './components/pages/MakeNewEntry';
import AddMethod from './components/pages/AddMethod';
import ViewEntries from './components/pages/ViewEntries';
import EntryPage from './components/pages/EntryPage';
import ApproveMethod from './components/pages/ApproveMethod';
import UpdateEntryPage from './components/pages/UpdateEntryPage';
import EntryEdit from './components/pages/EntryEdit';
import ViewMethods from './components/pages/ViewMethods';
import Footer from "./components/layouts/Footer";
import MethodEdit from "./components/pages/MethodEdit";
import ViewUsers from "./components/pages/ViewUsers";
import UserEdit from "./components/pages/UserEdit";
import EntriesSearch from "./components/pages/EntriesSearch";
import InternalDocuments from "./components/pages/InternalDocuments";
import GeneralInformation from "./components/pages/internalDocuments/GeneralInformation";
import Stipends from "./components/pages/internalDocuments/Stipends";
import CorporateIdentity from "./components/pages/internalDocuments/CorporateIdentity";
import PhdStudents from "./components/pages/internalDocuments/PhdStudents";

function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined,
    firstname: undefined,
    role: undefined
  });
  
  useEffect(() => {
    const checkLoginStatus = async () => {
      let token = localStorage.getItem("auth-token");
      if (token === null) {
        localStorage.setItem("auth-token", "");
        token = "";
      }
      const tokenRes = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/tokenIsValid`, null, { headers: { "x-auth-token": token}});
      if (tokenRes.data) {
        const getUserRes = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/getUser`, { headers: { "x-auth-token": token}});
        setUserData({ 
          token,
          user: getUserRes.data.userId,
          firstname: getUserRes.data.firstname,
          role: getUserRes.data.role,
          initials: getUserRes.data.initials,
          entries: getUserRes.data.entries
        });
      }
    };
    checkLoginStatus();
  }, []);

  return (
    <>
    
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/createUser" component={CreateUser} />
              <Route path="/changePassword" component={ChangePassword} />
              <Route path="/createNewPassword" component={CreateNewPassword} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/newEntry" component={MakeNewEntry} />
              <Route path="/addMethod" component={AddMethod} />
              <Route path="/viewEntries" component={ViewEntries} />
              <Route path="/entry" component={EntryPage} />
              <Route path="/approveMethod" component={ApproveMethod} />
              <Route path="/updateEntryPage" component={UpdateEntryPage} />
              <Route path="/entryEdit" component={EntryEdit} />
              <Route path="/viewMethods" component={ViewMethods} />
              <Route path="/methodEdit" component={MethodEdit} />
              <Route path="/userManagement" component={ViewUsers} />
              <Route path="/userEdit" component={UserEdit} />
              <Route path="/entriesSearch" component={EntriesSearch} />
              <Route path="/internalDocuments" component={InternalDocuments} />
              <Route path="/generalInformation" component={GeneralInformation} />
              <Route path="/stipends" component={Stipends} />
              <Route path="/corporateIdentity" component={CorporateIdentity} />
              <Route path="/phdStudents" component={PhdStudents} />
            </Switch>
          <Footer />
        </UserContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
