import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _ from 'underscore';

import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingBackdrop from '../feedback/LoadingBackdrop';
import NotificationSnackbar from "../feedback/NotificationSnackbar";


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

const sortByInputs = [
    {
        value: 'index',
        label: 'Entry Index'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'project',
        label: 'Project'
    },
];

export default function UpdateEntryPage() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ allEntriesButtonVariant, setAllEntriesButtonVariant ] = useState("outlined");
    const [ myEntriesButtonVariant, setMyEntriesButtonVariant ] = useState("outlined");
    const [ myStudentsButtonVariant, setMyStudentsButtonVariant ] = useState("outlined");
    const [ allEntriesButtonSize, setAllEntriesButtonSize ] = useState();
    const [ myEntriesButtonSize, setMyEntriesButtonSize ] = useState();
    const [ myStudentsButtonSize, setMyStudentsButtonSize ] = useState();

    const [ allEntries, setAllEntries ] = useState([]);
    const [ entries, setEntries ] = useState([]);
    const [ entriesLoaded, setEntriesLoaded ] = useState(false);
    let searchKey = "";
    const [ sortBy, setSortBy ] = useState("");
    const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
    const [ deleteDialogTitle, setDeleteDialogTitle ] = useState("Delete");
    const [ deleteEntry, setDeleteEntry ] = useState();
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        loadMyEntries();
    }, []);

    const loadMyEntries = async () => {
        try {
            setLoading(true);
            const entriesRes = await axiosClient.get("entry/getMyEntries");
            setEntries(entriesRes.data);
            setAllEntries(entriesRes.data);
            setEntriesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setMyEntriesButtonVariant("contained");
        setMyEntriesButtonSize("large");
        setAllEntriesButtonVariant("outlined");
        setAllEntriesButtonSize("medium");
        setMyStudentsButtonVariant("outlined");
        setMyStudentsButtonSize("medium");
    }

    const handleListItemClick = (e, index) => {
        localStorage.setItem("index", index);
        history.push("/entry");
    };

    const handleEditClick = (e, eId) => {
        localStorage.setItem("edit_entry_index", eId);
        history.push("/entryEdit");
    };

    /* const handleDeleteClick = (e, entry) => {
        setDeleteDialogTitle("Delete entry with index: " + entry.index + " ?")
        setOpenDeleteDialog(true);
        setDeleteEntry(entry);
    };

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    };

    const deleteEntryConfirm = async (e) => {
        try {
            setLoading(true);
            handleCloseDeleteDialog();
            const entryDeleteRes = await axiosClient.delete("entry/deleteEntry", {params: {entryId: deleteEntry.id}});
            setSeverity("success");
            setNotification(entryDeleteRes.data.responseMessage);
            sleep(5000).then(()=>{
                setLoading(false);
                history.push("/dashboard");
            });
        } catch (error) {
            setSeverity("error");
            setNotification("Something went wrong.");
        }
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }; */

    let compareRef = useRef();

    const filterEntries = () => {
        const results = [];
        allEntries.forEach(entry => {
            if (entry.index.toUpperCase().includes(searchKey.toUpperCase()) || entry.project.toUpperCase().includes(searchKey.toUpperCase()) || 
            entry.elnReferences.toUpperCase().includes(searchKey.toUpperCase()) || entry.methods.toUpperCase().includes(searchKey.toUpperCase())) {
                results.push(entry);
            }
        });
        setEntries(results);
    };
    
    const sortEntries = (sortKey) => {
        setEntries(_.sortBy(entries, sortKey));
    };
    
    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <React.Fragment>
            <CssBaseline />
            <main>
                <LoadingBackdrop open={loading} />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                <Container maxWidth="lg">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Update Entry
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                    
                    </Typography>
                </Container>
                </div>
                <Container maxWidth="md">
                    {(entriesLoaded) ? 
                        (
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="entries_search"
                                    label="Search Entries"
                                    name="entries_search"
                                    onChange={(e) => {searchKey=e.target.value; filterEntries();}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="entries_sort"
                                    name="entries_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortEntries(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        ) : (<div></div>)
                    }
                    {(entries.length < 1) ? (<h5>No results</h5>) : (<div></div>)}
                <List>
                    {entries.map(entry => (
                        <ListItem button onClick={(e) => handleListItemClick(e, entry.index)} key={entry.index}>
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={entry.index}
                                secondary={`Project: ${entry.project} || ELN reference(s): ${entry.elnReferences} || Methods/Tags: ${entry.methods}`} 
                            />
                            <ListItemSecondaryAction>
                                <Button ref={compareRef} color="secondary" variant="contained" onClick={(e) => handleEditClick(e, entry.index)}>
                                    Edit
                                </Button>
                                {/* <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteClick(e, entry)}>
                                    <DeleteForeverIcon />
                                </IconButton> */}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                </Container>
                {/* <Dialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{deleteDialogTitle}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This entry will be permanently deleted. Are you sure?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={deleteEntryConfirm} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
                        No
                    </Button>
                    </DialogActions>
                </Dialog> */}
            </main>
        </React.Fragment>
        )
        }
        {notification && (
                <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
            )}
        </>
    );
}
