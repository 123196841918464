import React, { useContext, useState, useEffect, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import {DropzoneArea} from 'material-ui-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Switch from '@material-ui/core/Switch';import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Link from '@material-ui/core/Link';
import LinkIcon from '@material-ui/icons/Link';

import NotificationSnackbar from "../../feedback/NotificationSnackbar";
import LoadingBackdrop from '../../feedback/LoadingBackdrop';

import UserContext from "../../../context/UserContext";
import { useHistory } from 'react-router-dom';
import Footer from '../../layouts/Footer';
import axios from "axios";
import { forEach } from 'underscore';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightBold,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    editModeSwitch: {
      float: 'right'
    }
}));

export default function PhdStudents() {
    const classes = useStyles();
    
    const { userData } = useContext(UserContext);
    const history = useHistory();

    const axiosClient = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
      headers: {
          "x-auth-token" : userData.token
      }
    });

    useEffect(() => {
      loadDocuments();
      loadForms();
      loadGraduateschools();
      loadConfirmations3FPs();
      loadConfirmations2FPs();
      loadConfirmations1FPs();
      loadLinks();
  }, []);

  const loadDocuments = async () => {
      try {
          setLoading(true);
          const documentsRes = await axiosClient.get("phdstudentsInternalDocuments/getDocuments")
          setDocuments(documentsRes.data);
          setLoading(false);
      } catch (err) {
          setLoading(false);
          setSeverity("error");
          err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
      }
  };

  const loadForms = async () => {
    try {
        setLoading(true);
        const formsRes = await axiosClient.get("phdstudentsInternalDocuments/getForms")
        setForms(formsRes.data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
};

const loadGraduateschools = async () => {
    try {
        setLoading(true);
        const graduateschoolsRes = await axiosClient.get("phdstudentsInternalDocuments/getGraduateschools")
        setGraduateschools(graduateschoolsRes.data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
};

const loadConfirmations3FPs = async () => {
    try {
        setLoading(true);
        const confirmations3fpsRes = await axiosClient.get("phdstudentsInternalDocuments/getConfirmations3FPs")
        setConfirmations3FPs(confirmations3fpsRes.data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
};

const loadConfirmations2FPs = async () => {
    try {
        setLoading(true);
        const confirmations2fpsRes = await axiosClient.get("phdstudentsInternalDocuments/getConfirmations2FPs")
        setConfirmations2FPs(confirmations2fpsRes.data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
};

const loadConfirmations1FPs = async () => {
    try {
        setLoading(true);
        const confirmations1fpsRes = await axiosClient.get("phdstudentsInternalDocuments/getConfirmations1FPs")
        setConfirmations1FPs(confirmations1fpsRes.data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
};

  const loadLinks = async () => {
    try {
        setLoading(true);
        const linksRes = await axiosClient.get("phdstudentsInternalDocuments/getLinks")
        setLinks(linksRes.data);
        setLoading(false);
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
};

    const [ editMode, setEditMode ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ documents, setDocuments ] = useState([]);
    const [ forms, setForms ] = useState([]);
    const [ graduateschools, setGraduateschools ] = useState([]);
    const [ confirmations3fps, setConfirmations3FPs ] = useState([]);
    const [ confirmations2fps, setConfirmations2FPs ] = useState([]);
    const [ confirmations1fps, setConfirmations1FPs ] = useState([]);
    const [ links, setLinks ] = useState([]);

    const [ docName, setDocName ] = useState("");
    const [ docFile, setDocFile ] = useState([]);
    const [ docFilename, setDocFilename ] = useState("");

    const [ formName, setFormName ] = useState("");
    const [ formFile, setFormFile ] = useState([]);
    const [ formFilename, setFormFilename ] = useState("");

    const [ graduateschoolName, setGraduateschoolName ] = useState("");
    const [ graduateschoolFile, setGraduateschoolFile ] = useState([]);
    const [ graduateschoolFilename, setGraduateschoolFilename ] = useState("");

    const [ confirmations3fpName, setConfirmations3FPName ] = useState("");
    const [ confirmations3fpFile, setConfirmations3FPFile ] = useState([]);
    const [ confirmations3fpFilename, setConfirmations3FPFilename ] = useState("");

    const [ confirmations2fpName, setConfirmations2FPName ] = useState("");
    const [ confirmations2fpFile, setConfirmations2FPFile ] = useState([]);
    const [ confirmations2fpFilename, setConfirmations2FPFilename ] = useState("");

    const [ confirmations1fpName, setConfirmations1FPName ] = useState("");
    const [ confirmations1fpFile, setConfirmations1FPFile ] = useState([]);
    const [ confirmations1fpFilename, setConfirmations1FPFilename ] = useState("");

    const [ linkName, setLinkName ] = useState("");
    const [ linkUrl, setLinkUrl ] = useState("");
    
    const [ docName_disabled, setDocName_disabled ] = useState(false);
    const [ formName_disabled, setFormName_disabled ] = useState(false);
    const [ graduateschoolName_disabled, setGraduateschoolName_disabled ] = useState(false);
    const [ confirmations3fpName_disabled, setConfirmations3FPName_disabled ] = useState(false);
    const [ confirmations2fpName_disabled, setConfirmations2FPName_disabled ] = useState(false);
    const [ confirmations1fpName_disabled, setConfirmations1FPName_disabled ] = useState(false);
    const [ linkName_disabled, setLinkName_disabled ] = useState(false);

    const [ openDocumentDialog, setOpenDocumentDialog ] = useState(false);
    const [ documentDialogTitle, setDocumentDialogTitle ] = useState("Upload Document");

    const [ openFormDialog, setOpenFormDialog ] = useState(false);
    const [ formDialogTitle, setFormDialogTitle ] = useState("Upload Forms/Templates");

    const [ openGraduateschoolDialog, setOpenGraduateschoolDialog ] = useState(false);
    const [ graduateschoolDialogTitle, setGraduateschoolDialogTitle ] = useState("Upload Graduate School");

    const [ openConfirmations3FPDialog, setOpenConfirmations3FPDialog ] = useState(false);
    const [ confirmations3fpDialogTitle, setConfirmations3FPDialogTitle ] = useState("Upload Confirmations 3FP");

    const [ openConfirmations2FPDialog, setOpenConfirmations2FPDialog ] = useState(false);
    const [ confirmations2fpDialogTitle, setConfirmations2FPDialogTitle ] = useState("Upload Confirmations 2FP");

    const [ openConfirmations1FPDialog, setOpenConfirmations1FPDialog ] = useState(false);
    const [ confirmations1fpDialogTitle, setConfirmations1FPDialogTitle ] = useState("Upload Confirmations 1FP");

    const [ openLinkDialog, setOpenLinkDialog ] = useState(false);
    const [ linkDialogTitle, setLinkDialogTitle ] = useState("Create Link");

    const [ openDeleteDocumentDialog, setOpenDeleteDocumentDialog ] = useState(false);
    const [ deleteDocumentDialogTitle, setDeleteDocumentDialogTitle ] = useState("Delete");
    const [ deleteDocumentName, setDeleteDocumentName ] = useState("");

    const [ openDeleteFormDialog, setOpenDeleteFormDialog ] = useState(false);
    const [ deleteFormDialogTitle, setDeleteFormDialogTitle ] = useState("Delete");
    const [ deleteFormName, setDeleteFormName ] = useState("");

    const [ openDeleteGraduateschoolDialog, setOpenDeleteGraduateschoolDialog ] = useState(false);
    const [ deleteGraduateschoolDialogTitle, setDeleteGraduateschoolDialogTitle ] = useState("Delete");
    const [ deleteGraduateschoolName, setDeleteGraduateschoolName ] = useState("");

    const [ openDeleteConfirmations3FPDialog, setOpenDeleteConfirmations3FPDialog ] = useState(false);
    const [ deleteConfirmations3FPDialogTitle, setDeleteConfirmations3FPDialogTitle ] = useState("Delete");
    const [ deleteConfirmations3FPName, setDeleteConfirmations3FPName ] = useState("");

    const [ openDeleteConfirmations2FPDialog, setOpenDeleteConfirmations2FPDialog ] = useState(false);
    const [ deleteConfirmations2FPDialogTitle, setDeleteConfirmations2FPDialogTitle ] = useState("Delete");
    const [ deleteConfirmations2FPName, setDeleteConfirmations2FPName ] = useState("");

    const [ openDeleteConfirmations1FPDialog, setOpenDeleteConfirmations1FPDialog ] = useState(false);
    const [ deleteConfirmations1FPDialogTitle, setDeleteConfirmations1FPDialogTitle ] = useState("Delete");
    const [ deleteConfirmations1FPName, setDeleteConfirmations1FPName ] = useState("");

    const [ openDeleteLinkDialog, setOpenDeleteLinkDialog ] = useState(false);
    const [ deleteLinkDialogTitle, setDeleteLinkDialogTitle ] = useState("Delete");
    const [ deleteLinkId, setDeleteLinkId ] = useState();


    // TODO: Check if same name exists in all documents, forms
    const handleDocDataChange = async (newFiles) => {
      setLoading(true);
      documents.forEach(document => {
        if (document.document_name.toUpperCase() === docName.toUpperCase()) {
          setSeverity("error");
          setNotification("Please change the document name. A document with same name already Exists.");
          sleep(5000).then(()=>{
              history.push("/internalDocuments");
          });
        }
      });
      const file = newFiles[0];
      if (file) {
          const newFile=new File([file], docName.replace(/\s+/g, '_')+"."+file.name.split('.').pop()); 
          setDocFile([newFile]);
          setDocFilename(docName.replace(/\s+/g, '_')+"."+file.name.split('.').pop())
          setDocName_disabled(true);
      }
      setLoading(false);
  };

  const handleDocDataDelete = (deletedFile) => {
      setDocName_disabled(false);
      setDocFile([]);
      setDocName("");
  };

  const clearDocFields = () => {
    setDocName_disabled(false);
    setDocFile([]);
    setDocName("");
};

const handleFormDataChange = async (newFiles) => {
  setLoading(true);
  forms.forEach(form => {
    if (form.form_name.toUpperCase() === formName.toUpperCase()) {
      setSeverity("error");
      setNotification("Please change the form name. A form with same name already Exists.");
      sleep(5000).then(()=>{
          history.push("/internalDocuments");
      });
    }
  });
  const file = newFiles[0];
  if (file) {
      const newFile=new File([file], formName.replace(/\s+/g, '_')+"."+file.name.split('.').pop()); 
      setFormFile([newFile]);
      setFormFilename(formName.replace(/\s+/g, '_')+"."+file.name.split('.').pop())
      setFormName_disabled(true);
  }
  setLoading(false);
};

const handleFormDataDelete = (deletedFile) => {
  setFormName_disabled(false);
  setFormFile([]);
  setFormName("");
};

const clearFormFields = () => {
setFormName_disabled(false);
setFormFile([]);
setFormName("");
};

const handleGraduateschoolDataChange = async (newFiles) => {
  setLoading(true);
  graduateschools.forEach(graduateschool => {
    if (graduateschool.graduateschool_name.toUpperCase() === graduateschoolName.toUpperCase()) {
      setSeverity("error");
      setNotification("Please change the graduateschool name. A graduateschool with same name already Exists.");
      sleep(5000).then(()=>{
          history.push("/internalDocuments");
      });
    }
  });
  const file = newFiles[0];
  if (file) {
      const newFile=new File([file], graduateschoolName.replace(/\s+/g, '_')+"."+file.name.split('.').pop()); 
      setGraduateschoolFile([newFile]);
      setGraduateschoolFilename(graduateschoolName.replace(/\s+/g, '_')+"."+file.name.split('.').pop())
      setGraduateschoolName_disabled(true);
  }
  setLoading(false);
};

const handleGraduateschoolDataDelete = (deletedFile) => {
  setGraduateschoolName_disabled(false);
  setGraduateschoolFile([]);
  setGraduateschoolName("");
};

const clearGraduateschoolFields = () => {
setGraduateschoolName_disabled(false);
setGraduateschoolFile([]);
setGraduateschoolName("");
};

const handleConfirmations3FPDataChange = async (newFiles) => {
  setLoading(true);
  confirmations3fps.forEach(confirmations3fp => {
    if (confirmations3fp.confirmations3fp_name.toUpperCase() === confirmations3fpName.toUpperCase()) {
      setSeverity("error");
      setNotification("Please change the confirmations3fp name. A confirmations3fp with same name already Exists.");
      sleep(5000).then(()=>{
          history.push("/internalDocuments");
      });
    }
  });
  const file = newFiles[0];
  if (file) {
      const newFile=new File([file], confirmations3fpName.replace(/\s+/g, '_')+"."+file.name.split('.').pop()); 
      setConfirmations3FPFile([newFile]);
      setConfirmations3FPFilename(confirmations3fpName.replace(/\s+/g, '_')+"."+file.name.split('.').pop())
      setConfirmations3FPName_disabled(true);
  }
  setLoading(false);
};

const handleConfirmations3FPDataDelete = (deletedFile) => {
  setConfirmations3FPName_disabled(false);
  setConfirmations3FPFile([]);
  setConfirmations3FPName("");
};

const clearConfirmations3FPFields = () => {
setConfirmations3FPName_disabled(false);
setConfirmations3FPFile([]);
setConfirmations3FPName("");
};

const handleConfirmations2FPDataChange = async (newFiles) => {
  setLoading(true);
  confirmations2fps.forEach(confirmations2fp => {
    if (confirmations2fp.confirmations2fp_name.toUpperCase() === confirmations2fpName.toUpperCase()) {
      setSeverity("error");
      setNotification("Please change the confirmations2fp name. A confirmations2fp with same name already Exists.");
      sleep(5000).then(()=>{
          history.push("/internalDocuments");
      });
    }
  });
  const file = newFiles[0];
  if (file) {
      const newFile=new File([file], confirmations2fpName.replace(/\s+/g, '_')+"."+file.name.split('.').pop()); 
      setConfirmations2FPFile([newFile]);
      setConfirmations2FPFilename(confirmations2fpName.replace(/\s+/g, '_')+"."+file.name.split('.').pop())
      setConfirmations2FPName_disabled(true);
  }
  setLoading(false);
};

const handleConfirmations2FPDataDelete = (deletedFile) => {
  setConfirmations2FPName_disabled(false);
  setConfirmations2FPFile([]);
  setConfirmations2FPName("");
};

const clearConfirmations2FPFields = () => {
setConfirmations2FPName_disabled(false);
setConfirmations2FPFile([]);
setConfirmations2FPName("");
};

const clearLinkFields = () => {
  setLinkName_disabled(false);
  setLinkUrl("");
  setLinkName("");
};

const handleConfirmations1FPDataChange = async (newFiles) => {
  setLoading(true);
  confirmations1fps.forEach(confirmations1fp => {
    if (confirmations1fp.confirmations1fp_name.toUpperCase() === confirmations1fpName.toUpperCase()) {
      setSeverity("error");
      setNotification("Please change the confirmations1fp name. A confirmations1fp with same name already Exists.");
      sleep(5000).then(()=>{
          history.push("/internalDocuments");
      });
    }
  });
  const file = newFiles[0];
  if (file) {
      const newFile=new File([file], confirmations1fpName.replace(/\s+/g, '_')+"."+file.name.split('.').pop()); 
      setConfirmations1FPFile([newFile]);
      setConfirmations1FPFilename(confirmations1fpName.replace(/\s+/g, '_')+"."+file.name.split('.').pop())
      setConfirmations1FPName_disabled(true);
  }
  setLoading(false);
};

const handleConfirmations1FPDataDelete = (deletedFile) => {
  setConfirmations1FPName_disabled(false);
  setConfirmations1FPFile([]);
  setConfirmations1FPName("");
};

const clearConfirmations1FPFields = () => {
setConfirmations1FPName_disabled(false);
setConfirmations1FPFile([]);
setConfirmations1FPName("");
};

  const handleCloseDocumentDialog = () => {
    setOpenDocumentDialog(false);
    setDocName_disabled(false);
};

const handleCloseFormDialog = () => {
  setOpenFormDialog(false);
  setFormName_disabled(false);
};

const handleCloseGraduateschoolDialog = () => {
  setOpenGraduateschoolDialog(false);
  setGraduateschoolName_disabled(false);
};

const handleCloseConfirmations3FPDialog = () => {
  setOpenConfirmations3FPDialog(false);
  setConfirmations3FPName_disabled(false);
};

const handleCloseConfirmations2FPDialog = () => {
  setOpenConfirmations2FPDialog(false);
  setConfirmations2FPName_disabled(false);
};

const handleCloseConfirmations1FPDialog = () => {
  setOpenConfirmations1FPDialog(false);
  setConfirmations1FPName_disabled(false);
};

const handleCloseLinkDialog = () => {
  setOpenLinkDialog(false);
};

const handleCloseDeleteDocumentDialog = () => {
  setOpenDeleteDocumentDialog(false);
}

const handleCloseDeleteFormDialog = () => {
  setOpenDeleteFormDialog(false);
}

const handleCloseDeleteGraduateschoolDialog = () => {
  setOpenDeleteGraduateschoolDialog(false);
}

const handleCloseDeleteConfirmations3FPDialog = () => {
  setOpenDeleteConfirmations3FPDialog(false);
}

const handleCloseDeleteConfirmations2FPDialog = () => {
  setOpenDeleteConfirmations2FPDialog(false);
}

const handleCloseDeleteConfirmations1FPDialog = () => {
  setOpenDeleteConfirmations1FPDialog(false);
}

const handleCloseDeleteLinkDialog = () => {
  setOpenDeleteLinkDialog(false);
}

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time));
};

  const uploadDocument = async (e) => {
    setOpenDocumentDialog(false);
    setLoading(true);
    try {
        const documentUpload = new FormData();
        if(docFile[0]) {documentUpload.append("phdstudentsDocument", docFile[0]);}
        documentUpload.append("document_name", docName);
        documentUpload.append("document_file", docFilename);
        const documentUploadRes = await axiosClient.post("phdstudentsInternalDocuments/documentUpload", documentUpload);
        clearDocFields();
        setLoading(false);
        setSeverity("success");
        setNotification(documentUploadRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const uploadForm = async (e) => {
    setOpenFormDialog(false);
    setLoading(true);
    try {
        const formUpload = new FormData();
        if(docFile[0]) {formUpload.append("phdstudentsForm", docFile[0]);}
        formUpload.append("form_name", docName);
        formUpload.append("form_file", docFilename);
        const formUploadRes = await axiosClient.post("phdstudentsInternalDocuments/formUpload", formUpload);
        clearDocFields();
        setLoading(false);
        setSeverity("success");
        setNotification(formUploadRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const uploadGraduateschool = async (e) => {
    setOpenGraduateschoolDialog(false);
    setLoading(true);
    try {
        const graduateschoolUpload = new FormData();
        if(docFile[0]) {graduateschoolUpload.append("phdstudentsGraduateschool", docFile[0]);}
        graduateschoolUpload.append("graduateschool_name", docName);
        graduateschoolUpload.append("graduateschool_file", docFilename);
        const graduateschoolUploadRes = await axiosClient.post("phdstudentsInternalDocuments/graduateschoolUpload", graduateschoolUpload);
        clearDocFields();
        setLoading(false);
        setSeverity("success");
        setNotification(graduateschoolUploadRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const uploadConfirmations3FP = async (e) => {
    setOpenConfirmations3FPDialog(false);
    setLoading(true);
    try {
        const confirmations3fpUpload = new FormData();
        if(docFile[0]) {confirmations3fpUpload.append("phdstudentsConfirmations3FP", docFile[0]);}
        confirmations3fpUpload.append("confirmations3fp_name", docName);
        confirmations3fpUpload.append("confirmations3fp_file", docFilename);
        const confirmations3fpUploadRes = await axiosClient.post("phdstudentsInternalDocuments/confirmations3fpUpload", confirmations3fpUpload);
        clearDocFields();
        setLoading(false);
        setSeverity("success");
        setNotification(confirmations3fpUploadRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const uploadConfirmations2FP = async (e) => {
    setOpenConfirmations2FPDialog(false);
    setLoading(true);
    try {
        const confirmations2fpUpload = new FormData();
        if(docFile[0]) {confirmations2fpUpload.append("phdstudentsConfirmations2FP", docFile[0]);}
        confirmations2fpUpload.append("confirmations2fp_name", docName);
        confirmations2fpUpload.append("confirmations2fp_file", docFilename);
        const confirmations2fpUploadRes = await axiosClient.post("phdstudentsInternalDocuments/confirmations2fpUpload", confirmations2fpUpload);
        clearDocFields();
        setLoading(false);
        setSeverity("success");
        setNotification(confirmations2fpUploadRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };

  const uploadConfirmations1FP = async (e) => {
    setOpenConfirmations1FPDialog(false);
    setLoading(true);
    try {
        const confirmations1fpUpload = new FormData();
        if(docFile[0]) {confirmations1fpUpload.append("phdstudentsConfirmations1FP", docFile[0]);}
        confirmations1fpUpload.append("confirmations1fp_name", docName);
        confirmations1fpUpload.append("confirmations1fp_file", docFilename);
        const confirmations1fpUploadRes = await axiosClient.post("phdstudentsInternalDocuments/confirmations1fpUpload", confirmations1fpUpload);
        clearDocFields();
        setLoading(false);
        setSeverity("success");
        setNotification(confirmations1fpUploadRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  };


  const createLink = async () => {
    setOpenLinkDialog(false);
    setLoading(true);
    try {
        const createLinkRes = await axiosClient.post("phdstudentsInternalDocuments/createLink", {link_name:linkName, link_url:linkUrl});
        clearLinkFields();
        setLoading(false);
        setSeverity("success");
        setNotification(createLinkRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (err) {
        setLoading(false);
        setSeverity("error");
        err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
    }
  }

  const handleDeleteDocumentClick = (e, doc) => {
    setDeleteDocumentDialogTitle("Are you sure you want to delete the document: " + doc.document_name+ "?");
    setDeleteDocumentName(doc.document_name);
    setOpenDeleteDocumentDialog(true);
  }

  const handleDeleteFormClick = (e, doc) => {
    setDeleteFormDialogTitle("Are you sure you want to delete the document: " + doc.form_name+ "?");
    setDeleteFormName(doc.form_name);
    setOpenDeleteFormDialog(true);
  }

  const handleDeleteGraduateschoolClick = (e, doc) => {
    setDeleteGraduateschoolDialogTitle("Are you sure you want to delete the document: " + doc.graduateschool_name+ "?");
    setDeleteGraduateschoolName(doc.graduateschool_name);
    setOpenDeleteGraduateschoolDialog(true);
  }

  const handleDeleteConfirmations3FPClick = (e, doc) => {
    setDeleteConfirmations3FPDialogTitle("Are you sure you want to delete the document: " + doc.confirmations3fp_name+ "?");
    setDeleteConfirmations3FPName(doc.confirmations3fp_name);
    setOpenDeleteConfirmations3FPDialog(true);
  }

  const handleDeleteConfirmations2FPClick = (e, doc) => {
    setDeleteConfirmations2FPDialogTitle("Are you sure you want to delete the document: " + doc.confirmations2fp_name+ "?");
    setDeleteConfirmations2FPName(doc.confirmations2fp_name);
    setOpenDeleteConfirmations2FPDialog(true);
  }

  const handleDeleteConfirmations1FPClick = (e, doc) => {
    setDeleteConfirmations1FPDialogTitle("Are you sure you want to delete the document: " + doc.confirmations1fp_name+ "?");
    setDeleteConfirmations1FPName(doc.confirmations1fp_name);
    setOpenDeleteConfirmations1FPDialog(true);
  }
  
  const handleDeleteLinkClick = (e, link) => {
    setDeleteLinkDialogTitle("Are you sure you want to delete the link: " + link.link_name+ "?");
    setDeleteLinkId(link.id);
    setOpenDeleteLinkDialog(true);
  }

  const deleteDocumentConfirm = async (e) => {
    try {
        setOpenDeleteDocumentDialog(false);
        const documentDeleteRes = await axiosClient.delete("phdstudentsInternalDocuments/deleteDocument", {params: {document_name: deleteDocumentName}});
        setSeverity("success");
        setNotification(documentDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  const deleteFormConfirm = async (e) => {
    try {
        setOpenDeleteFormDialog(false);
        const formDeleteRes = await axiosClient.delete("phdstudentsInternalDocuments/deleteForm", {params: {form_name: deleteFormName}});
        setSeverity("success");
        setNotification(formDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  const deleteGraduateschoolConfirm = async (e) => {
    try {
        setOpenDeleteGraduateschoolDialog(false);
        const graduateschoolDeleteRes = await axiosClient.delete("phdstudentsInternalDocuments/deleteGraduateschool", {params: {graduateschool_name: deleteGraduateschoolName}});
        setSeverity("success");
        setNotification(graduateschoolDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  const deleteConfirmations3FPConfirm = async (e) => {
    try {
        setOpenDeleteConfirmations3FPDialog(false);
        const confirmations3fpDeleteRes = await axiosClient.delete("phdstudentsInternalDocuments/deleteConfirmations3FP", {params: {confirmations3fp_name: deleteConfirmations3FPName}});
        setSeverity("success");
        setNotification(confirmations3fpDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  const deleteConfirmations2FPConfirm = async (e) => {
    try {
        setOpenDeleteConfirmations2FPDialog(false);
        const confirmations2fpDeleteRes = await axiosClient.delete("phdstudentsInternalDocuments/deleteConfirmations2FP", {params: {confirmations2fp_name: deleteConfirmations2FPName}});
        setSeverity("success");
        setNotification(confirmations2fpDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  const deleteConfirmations1FPConfirm = async (e) => {
    try {
        setOpenDeleteConfirmations1FPDialog(false);
        const confirmations1fpDeleteRes = await axiosClient.delete("phdstudentsInternalDocuments/deleteConfirmations1FP", {params: {confirmations1fp_name: deleteConfirmations1FPName}});
        setSeverity("success");
        setNotification(confirmations1fpDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  const deleteLinkConfirm = async (e) => {
    try {
        setOpenDeleteLinkDialog(false);
        const linkDeleteRes = await axiosClient.delete("phdstudentsInternalDocuments/deleteLink", {params: {link_id: deleteLinkId}});
        setSeverity("success");
        setNotification(linkDeleteRes.data.responseMessage);
        sleep(3000).then(()=>{
            history.push("/internalDocuments");
        });
    } catch (error) {
      setSeverity("error");
      setNotification("Something went wrong.");
    }
  }

  let compareRef = useRef();

    return (
      <>
      {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
      (
    <React.Fragment>
      <CssBaseline />
      <main>
      
        <Container  maxWidth="lg">
          <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
              TRR225 PhD Students
              {userData.role === 'admin' ? (
                <Typography component="h6" variant='h6' align='right' color='textPrimary'>
                  Toggle Edit Mode
                  <Switch
                    checked={editMode}
                    onChange={(e) => setEditMode(!editMode)}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    className={classes.editModeSwitch}
                  />
                </Typography>
              ) : (<div></div>)}
          </Typography>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Documents {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={(e) => setOpenDocumentDialog(true)}
                >
                  Upload Document
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                    {documents.map(document => (
                        <ListItem key={document.document_name}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Link href={process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/internaldocuments/phdstudentsdocs/"+document.document_file}>
                                            {document.document_name}
                                        </Link>}
                            />
                            <ListItemSecondaryAction>
                                {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteDocument(document.document_name)}>
                                    Edit
                                </Button> */}
                                {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteDocumentClick(e, document)}>
                                    <DeleteForeverIcon />
                                </IconButton>) : (<div></div>)}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Forms/Templates {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={(e) => setOpenFormDialog(true)}
                >
                  Upload Form
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                    {forms.map(form => (
                        <ListItem key={form.form_name}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Link href={process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/internaldocuments/phdstudentsdocs/"+form.form_file}>
                                            {form.form_name}
                                        </Link>}
                            />
                            <ListItemSecondaryAction>
                                {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteForm(form.form_name)}>
                                    Edit
                                </Button> */}
                                {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteFormClick(e, form)}>
                                    <DeleteForeverIcon />
                                </IconButton>) : (<div></div>)}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Graduate Schools {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={(e) => setOpenGraduateschoolDialog(true)}
                >
                  Upload Graduate school document
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                    {graduateschools.map(graduateschool => (
                        <ListItem key={graduateschool.graduateschool_name}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Link href={process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/internaldocuments/phdstudentsdocs/"+graduateschool.graduateschool_file}>
                                            {graduateschool.graduateschool_name}
                                        </Link>}
                            />
                            <ListItemSecondaryAction>
                                {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteGraduateschool(graduateschool.graduateschool_name)}>
                                    Edit
                                </Button> */}
                                {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteGraduateschoolClick(e, graduateschool)}>
                                    <DeleteForeverIcon />
                                </IconButton>) : (<div></div>)}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Confirmations 3FP {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={(e) => setOpenConfirmations3FPDialog(true)}
                >
                  Upload Confirmations 3FP
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                    {confirmations3fps.map(confirmations3fp => (
                        <ListItem key={confirmations3fp.confirmations3fp_name}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Link href={process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/internaldocuments/phdstudentsdocs/"+confirmations3fp.confirmations3fp_file}>
                                            {confirmations3fp.confirmations3fp_name}
                                        </Link>}
                            />
                            <ListItemSecondaryAction>
                                {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteConfirmations3FP(confirmations3fp.confirmations3fp_name)}>
                                    Edit
                                </Button> */}
                                {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteConfirmations3FPClick(e, confirmations3fp)}>
                                    <DeleteForeverIcon />
                                </IconButton>) : (<div></div>)}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Confirmations 2FP {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={(e) => setOpenConfirmations2FPDialog(true)}
                >
                  Upload Confirmations 2FP
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                    {confirmations2fps.map(confirmations2fp => (
                        <ListItem key={confirmations2fp.confirmations2fp_name}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Link href={process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/internaldocuments/phdstudentsdocs/"+confirmations2fp.confirmations2fp_file}>
                                            {confirmations2fp.confirmations2fp_name}
                                        </Link>}
                            />
                            <ListItemSecondaryAction>
                                {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteConfirmations2FP(confirmations2fp.confirmations2fp_name)}>
                                    Edit
                                </Button> */}
                                {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteConfirmations2FPClick(e, confirmations2fp)}>
                                    <DeleteForeverIcon />
                                </IconButton>) : (<div></div>)}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Confirmations 1FP {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={(e) => setOpenConfirmations1FPDialog(true)}
                >
                  Upload Confirmations 1FP
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                    {confirmations1fps.map(confirmations1fp => (
                        <ListItem key={confirmations1fp.confirmations1fp_name}>
                            <ListItemIcon>
                              <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Link href={process.env.REACT_APP_BACKEND_BASE_URL+"/uploads/internaldocuments/phdstudentsdocs/"+confirmations1fp.confirmations1fp_file}>
                                            {confirmations1fp.confirmations1fp_name}
                                        </Link>}
                            />
                            <ListItemSecondaryAction>
                                {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteConfirmations1FP(confirmations1fp.confirmations1fp_name)}>
                                    Edit
                                </Button> */}
                                {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteConfirmations1FPClick(e, confirmations1fp)}>
                                    <DeleteForeverIcon />
                                </IconButton>) : (<div></div>)}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Links {editMode ? ( <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<LinkIcon />}
                  onClick={(e) => setOpenLinkDialog(true)}
                >
                  Create Link
                </Button>
              </>) : (<></>)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {links.map(link => (
                    <ListItem key={link.link_name}>
                        <ListItemIcon>
                          <LinkIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Link href={link.link_url}>
                                        {link.link_name}
                                    </Link>}
                        />
                        <ListItemSecondaryAction>
                            {/* <Button ref={compareRef} color="primary" variant="contained" onClick={(e) => deleteDocument(document.document_name)}>
                                Edit
                            </Button> */}
                            {editMode ? (<IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteLinkClick(e, link)}>
                                <DeleteForeverIcon />
                            </IconButton>) : (<div></div>)}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Container>
        <Dialog
          open={openDocumentDialog}
          onClose={handleCloseDocumentDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{documentDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Upload.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={docName_disabled}
            value={docName}
            fullWidth
            id="doc_name"
            label="Document Name"
            name="doc_name"
            onChange={(e) => setDocName(e.target.value)}
          />
          <DropzoneArea
            acceptedFiles={[]}
            showFileNamesInPreview={true}
            filesLimit={1}
            onChange={handleDocDataChange}
            onDelete={handleDocDataDelete}
            useChipsForPreview={true}
            dropzoneText="Click to Browse or Drag a file here"
            showAlerts={false}
          />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={uploadDocument}>
              Upload
          </Button>
          <Button onClick={handleCloseDocumentDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openFormDialog}
          onClose={handleCloseFormDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Upload.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={docName_disabled}
            value={docName}
            fullWidth
            id="doc_name"
            label="Form Name"
            name="doc_name"
            onChange={(e) => setDocName(e.target.value)}
          />
          <DropzoneArea
            acceptedFiles={[]}
            showFileNamesInPreview={true}
            filesLimit={1}
            onChange={handleDocDataChange}
            onDelete={handleDocDataDelete}
            useChipsForPreview={true}
            dropzoneText="Click to Browse or Drag a file here"
            showAlerts={false}
          />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={uploadForm}>
              Upload
          </Button>
          <Button onClick={handleCloseFormDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openGraduateschoolDialog}
          onClose={handleCloseGraduateschoolDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{graduateschoolDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Upload.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={docName_disabled}
            value={docName}
            fullWidth
            id="doc_name"
            label="Graduateschool Name"
            name="doc_name"
            onChange={(e) => setDocName(e.target.value)}
          />
          <DropzoneArea
            acceptedFiles={[]}
            showFileNamesInPreview={true}
            filesLimit={1}
            onChange={handleDocDataChange}
            onDelete={handleDocDataDelete}
            useChipsForPreview={true}
            dropzoneText="Click to Browse or Drag a file here"
            showAlerts={false}
          />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={uploadGraduateschool}>
              Upload
          </Button>
          <Button onClick={handleCloseGraduateschoolDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openConfirmations3FPDialog}
          onClose={handleCloseConfirmations3FPDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmations3fpDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Upload.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={docName_disabled}
            value={docName}
            fullWidth
            id="doc_name"
            label="Confirmations3FP Name"
            name="doc_name"
            onChange={(e) => setDocName(e.target.value)}
          />
          <DropzoneArea
            acceptedFiles={[]}
            showFileNamesInPreview={true}
            filesLimit={1}
            onChange={handleDocDataChange}
            onDelete={handleDocDataDelete}
            useChipsForPreview={true}
            dropzoneText="Click to Browse or Drag a file here"
            showAlerts={false}
          />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={uploadConfirmations3FP}>
              Upload
          </Button>
          <Button onClick={handleCloseConfirmations3FPDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openConfirmations2FPDialog}
          onClose={handleCloseConfirmations2FPDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmations2fpDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Upload.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={docName_disabled}
            value={docName}
            fullWidth
            id="doc_name"
            label="Confirmations2FP Name"
            name="doc_name"
            onChange={(e) => setDocName(e.target.value)}
          />
          <DropzoneArea
            acceptedFiles={[]}
            showFileNamesInPreview={true}
            filesLimit={1}
            onChange={handleDocDataChange}
            onDelete={handleDocDataDelete}
            useChipsForPreview={true}
            dropzoneText="Click to Browse or Drag a file here"
            showAlerts={false}
          />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={uploadConfirmations2FP}>
              Upload
          </Button>
          <Button onClick={handleCloseConfirmations2FPDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openConfirmations1FPDialog}
          onClose={handleCloseConfirmations1FPDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{confirmations1fpDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Upload.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            disabled={docName_disabled}
            value={docName}
            fullWidth
            id="doc_name"
            label="Confirmations1FP Name"
            name="doc_name"
            onChange={(e) => setDocName(e.target.value)}
          />
          <DropzoneArea
            acceptedFiles={[]}
            showFileNamesInPreview={true}
            filesLimit={1}
            onChange={handleDocDataChange}
            onDelete={handleDocDataDelete}
            useChipsForPreview={true}
            dropzoneText="Click to Browse or Drag a file here"
            showAlerts={false}
          />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={uploadConfirmations1FP}>
              Upload
          </Button>
          <Button onClick={handleCloseConfirmations1FPDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openLinkDialog}
          onClose={handleCloseLinkDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{linkDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Please fill in the following and click on Create Link.
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="normal"
            required
            value={linkName}
            fullWidth
            id="link_name"
            label="Link Name"
            name="link_name"
            onChange={(e) => setLinkName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            value={linkUrl}
            fullWidth
            id="link_url"
            label="Link URL"
            name="link_url"
            onChange={(e) => setLinkUrl(e.target.value)}
          />
          </DialogContent>
          <DialogActions>
          <Button onClick={createLink} color="primary" type="submit">
              Create Link
          </Button>
          <Button onClick={handleCloseLinkDialog} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteDocumentDialog}
          onClose={handleCloseDeleteDocumentDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteDocumentDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteDocumentConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteDocumentDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteFormDialog}
          onClose={handleCloseDeleteFormDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteFormDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteFormConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteFormDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteGraduateschoolDialog}
          onClose={handleCloseDeleteGraduateschoolDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteGraduateschoolDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteGraduateschoolConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteGraduateschoolDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteConfirmations3FPDialog}
          onClose={handleCloseDeleteConfirmations3FPDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteConfirmations3FPDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteConfirmations3FPConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteConfirmations3FPDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteConfirmations2FPDialog}
          onClose={handleCloseDeleteConfirmations2FPDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteConfirmations2FPDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteConfirmations2FPConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteConfirmations2FPDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteConfirmations1FPDialog}
          onClose={handleCloseDeleteConfirmations1FPDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteConfirmations1FPDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteConfirmations1FPConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteConfirmations1FPDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      <Dialog
          open={openDeleteLinkDialog}
          onClose={handleCloseDeleteLinkDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{deleteLinkDialogTitle}</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={deleteLinkConfirm} color="secondary">
              Yes
          </Button>
          <Button onClick={handleCloseDeleteLinkDialog} color="secondary" autoFocus>
              No
          </Button>
          </DialogActions>
      </Dialog>
      </main>
      {notification && (
                <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
            )}
            <LoadingBackdrop open={loading} />
    </React.Fragment>
    )
    }
    </>
  )
}
