import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import axios from "axios";
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';

import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#005896",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
    margin: theme.spacing(4)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    margin: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  paperList: {
    width: 300,
    height: 300,
    overflow: 'auto',
  },
}));

export default function MethodEdit() {
  const classes = useStyles();
  
  const { userData } = useContext(UserContext);
  const history = useHistory();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
  }); 
  
  const [ method, setMethod ] = useState();
  const [ methodName, setMethodName ] = useState();
  const [ methodDescription, setMethodDescription ] = useState();
  const [ methodCategory, setMethodCategory ] = useState("Other");

  const [ methodsDescription, setMethodsDescription ] = useState([]);

  const [ notification, setNotification ] = useState();
  const [ severity, setSeverity ] = useState();

  const [ submitDisabled, setSubmitDisabled ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ methodRequestTokenValid, setMethodRequestTokenValid ] = useState(true);
  const [ methodRequest, setMethodRequest ] = useState();
  const [ requestedBy, setRequestedBy ] = useState();
  
  const [ openInfoDialog, setOpenInfoDialog ] = useState(false);

  useEffect(() => {
    const loadMethod = async () => {
         if(!method && localStorage.getItem("edit_method_id")){
            try {
                const methodRes = await axiosClient.get("/methods/getMethod", {params: {id: localStorage.getItem("edit_method_id")}});
                setMethod(methodRes.data);
                setMethodName(methodRes.data.availableMethod);
                setMethodDescription(methodRes.data.description);
                setMethodCategory(methodRes.data.category);
            } catch (error) {
                
            }
         }
    }
    setLoading(true);
    loadMethod();
    setLoading(false);
  }, []);

  const validated = () => {
    
  }

  const clearFields = () => {
    setMethodName("");
  }

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time));
  }

  const submit = async (e, mId) => {
    e.preventDefault();    
    setSubmitDisabled(true);
    setLoading(true);
    try {
        const updateMethodRes = await axiosClient.put("/methods/updateMethod", { 
            id: method.id,
            availableMethod: methodName,
            description: methodDescription,
            category: methodCategory,    
        });
        setSeverity("success");
        setNotification(updateMethodRes.data.responseMessage);
        e.target.reset();
        clearFields();
        sleep(5000).then(()=>{
        setLoading(false);
        history.push("/dashboard");
        });
   } catch (err) {
    setSubmitDisabled(false);
    setLoading(false);
    setSeverity("error");
    err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
   }
  }

  return (
    <>
    {  (!userData.token) ?
      (<div>You are not authorised to access this section. Please login.</div>) : (
      (!methodRequestTokenValid) ?
      (<div>Either a decision regarding the concerned method has already been made or the token has expired.</div>) : (
      <main /* style={{backgroundImage: `url(${"/images/elni-bg.jpg"})`}} */>
      <LoadingBackdrop open={loading} />
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}/*  style={{backgroundColor: "#ffffff"}} */>
        <Typography component="h1" variant="h5">
          Edit Method/Tag
        </Typography>
        {notification && (
          <NotificationSnackbar message={notification} severity={severity} open={notification} clearNotification={() => setNotification(undefined)} />
        )}
        <form className={classes.form} onSubmit={submit.bind(this)} noValidate>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table" >
                <TableBody>
                    <StyledTableRow key="methodName">
                        <StyledTableCell component="th" scope="row">
                            Method/Tag Name:
                        </StyledTableCell>
                        <StyledTableCell >
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          disabled
                          id="method"
                          name="method"
                          value={methodName}
                          onChange={(e) => setMethodName(e.target.value)}
                        />
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="description">
                        <StyledTableCell component="th" scope="row">
                            Description:
                        </StyledTableCell>
                        <StyledTableCell >
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="description"
                          name="method"
                          value={methodDescription}
                          onChange={(e) => setMethodDescription(e.target.value)}
                        />
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="category">
                        <StyledTableCell component="th" scope="row">
                            Category:
                        </StyledTableCell>
                        <StyledTableCell >
                            <FormControl variant="outlined"
                                margin="normal"
                                required
                                fullWidth 
                                className={classes.formControl}>
                                <InputLabel id="category_label">Category</InputLabel>
                                <Select
                                    labelId="Category"
                                    required
                                    fullWidth
                                    id="category"
                                    value={methodCategory}
                                    onChange={(e) => {setMethodCategory(e.target.value)}}
                                    >
                                    <MenuItem key={"Analytics"} value={"Analytics"}>Analytics</MenuItem>
                                    <MenuItem key={"Bioinks/Materials"} value={"Bioinks/Materials"}>Bioinks/Materials</MenuItem>
                                    <MenuItem key={"Cell Culture/Animals"} value={"Cell Culture/Animals"}>Cell Culture/Animals</MenuItem>
                                    <MenuItem key={"Printing/Methods"} value={"Printing/Methods"}>Printing/Methods</MenuItem>
                                    <MenuItem key={"Imaging/Modelling"} value={"Imaging/Modelling"}>Imaging/Modelling</MenuItem>
                                    <MenuItem key={"Other"} value={"Other"}>Other</MenuItem>
                                </Select>
                            </FormControl>
                          </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            </TableContainer>
            <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitDisabled}
            >
            Update this method/tag
          </Button>
        </form>
      </div>
    </Container>
    </main>
    ))
  }
  </>);
}