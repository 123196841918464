import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _, { methods } from 'underscore';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { TextField } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    paperList: {
        width: 240,
        height: 300,
        overflow: 'auto',
    },
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

const sortByInputs = [
    {
        value: 'index',
        label: 'Entry Index'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'project',
        label: 'Project'
    },
];

export default function EntriesSearch() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ allEntriesButtonVariant, setAllEntriesButtonVariant ] = useState("outlined");
    const [ myEntriesButtonVariant, setMyEntriesButtonVariant ] = useState("outlined");
    const [ myStudentsButtonVariant, setMyStudentsButtonVariant ] = useState("outlined");
    const [ allEntriesButtonSize, setAllEntriesButtonSize ] = useState();
    const [ myEntriesButtonSize, setMyEntriesButtonSize ] = useState();
    const [ myStudentsButtonSize, setMyStudentsButtonSize ] = useState();

    const [ allEntries, setAllEntries ] = useState([]);
    const [ entries, setEntries ] = useState([]);
    const [ entriesLoaded, setEntriesLoaded ] = useState(false);
    const [ nameSearchKey, setNameSearchKey ] = useState("");
    const [ subProjectSearchKey, setSubProjectSearchKey ] = useState("");
    let methodsAll = [];
    const [ sortBy, setSortBy ] = useState("");
    
    const [ analyticsMethods, setAnalyticsMethods ] = useState([]);
    const [ bioinksMethods, setBioinksMethods ] = useState([]);
    const [ cellMethods, setCellMethods ] = useState([]);
    const [ imagingMethods, setImagingMethods ] = useState([]);
    const [ printingMethods, setPrintingMethods ] = useState([]);
    
    const [ methodsList, setMethodsList ] = useState([]);
    const [ methodsDescription, setMethodsDescription ] = useState([]);
    const [ methodsArray, setMethodsArray ] = useState([]);
    const [ methods, setMethods ] = useState("");
        
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        loadMethods();
    }, []);

    const loadAllEntries = async () => {
        try {
            setLoading(true);
            const entriesRes = await axiosClient.post("entry/filterEntries", {methodsArray, name: nameSearchKey, subProject: subProjectSearchKey});
            const subProjectRes = await axiosClient.get("users/getSubProjects");
            let filteredEntries = [];
            entriesRes.data.forEach(entry => {
                subProjectRes.data.forEach(user => {
                    if (user.id === entry.userId/*  && (user.firstname.includes(nameSearchKey) || user.lastname.includes(nameSearchKey)) && user.subProject.includes(subProjectSearchKey) */) {
                        entry.subProject = user.subProject;
                        entry.createdBy = user.firstname + ' ' + user.lastname;
                    }
                });
                if ((entry.createdBy.toUpperCase().includes(nameSearchKey.toUpperCase()) && (entry.subProject.toUpperCase().includes(subProjectSearchKey.toUpperCase())))) {
                    filteredEntries.push(entry);
                }
                
            });
            setEntries(filteredEntries);
            setAllEntries(filteredEntries);
            setEntriesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
    };

    const loadMethods = async () => {
        try {
          const getAllMethodsRes = await axiosClient.get("methods/getAll");
          let availableMethods = [];
          for(let i = 0; i < getAllMethodsRes.data.length; i++) {
            availableMethods.push(" " + getAllMethodsRes.data[i].availableMethod);
          }
          setMethodsList(availableMethods);
          setMethodsDescription(getAllMethodsRes.data);
          methodsAll = getAllMethodsRes.data;
          let analyticsBuild = [];
          let bioinksBuild = [];
          let cellBuild = [];
          let imagingBuild = [];
          let printingBuild = [];
          getAllMethodsRes.data.forEach(methodDetails => {
            methodDetails.checked = false;
            if (methodDetails.category === 'Analytics') {
              analyticsBuild.push(methodDetails);
            }
            if (methodDetails.category === 'Bioinks/Materials') {
              bioinksBuild.push(methodDetails);
            }
            if (methodDetails.category === 'Cell Culture/Animals') {
              cellBuild.push(methodDetails);
            }
            if (methodDetails.category === 'Imaging/Modelling') {
              imagingBuild.push(methodDetails);
            }
            if (methodDetails.category === 'Printing/Methods') {
              printingBuild.push(methodDetails);
            }
          });
          analyticsBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
          bioinksBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
          cellBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
          imagingBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
          printingBuild.sort((a,b) => (a.availableMethod > b.availableMethod) ? 1 : ((b.availableMethod > a.availableMethod) ? -1 : 0));
          setAnalyticsMethods(analyticsBuild);
          setBioinksMethods(bioinksBuild);
          setCellMethods(cellBuild);
          setImagingMethods(imagingBuild);
          setPrintingMethods(printingBuild);
        } catch (err) {
          setLoading(false);
          setSeverity("error");
          err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
      };

      const handleCheck = (methodDetails) => {
        let methodsChecked = methodsArray;
        if (methodDetails.checked) {
          if (methodDetails.category === 'Analytics') {
            let analyticsBuild = analyticsMethods;
            analyticsBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = false;
              }
            });
            setAnalyticsMethods(analyticsBuild);
          }
          else if (methodDetails.category === 'Bioinks/Materials') {
            let bioBuild = bioinksMethods;
            bioBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = false;
              }
            });
            setBioinksMethods(bioBuild);
          }
          else if (methodDetails.category === 'Cell Culture/Animals') {
            let cellBuild = cellMethods;
            cellBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = false;
              }
            });
            setCellMethods(cellBuild);
          }
          else if (methodDetails.category === 'Imaging/Modelling') {
            let imagingBuild = imagingMethods;
            imagingBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = false;
              }
            });
            setImagingMethods(imagingBuild);
          }
          else if (methodDetails.category === 'Printing/Methods') {
            let printingBuild = printingMethods;
            printingBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = false;
              }
            });
            setPrintingMethods(printingBuild);
          }
          if (methodsChecked.includes(methodDetails.availableMethod)) {
            methodsChecked.splice(methodsChecked.indexOf(methodDetails.availableMethod), 1);
          }
        } else {
          if (methodDetails.category === 'Analytics') {
            let analyticsBuild = analyticsMethods;
            analyticsBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = true;
              }
            });
            setAnalyticsMethods(analyticsBuild);
          }
          else if (methodDetails.category === 'Bioinks/Materials') {
            let bioBuild = bioinksMethods;
            bioBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = true;
              }
            });
            setBioinksMethods(bioBuild);
          }
          else if (methodDetails.category === 'Cell Culture/Animals') {
            let cellBuild = cellMethods;
            cellBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = true;
              }
            });
            setCellMethods(cellBuild);
          }
          else if (methodDetails.category === 'Imaging/Modelling') {
            let imagingBuild = imagingMethods;
            imagingBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = true;
              }
            });
            setImagingMethods(imagingBuild);
          }
          else if (methodDetails.category === 'Printing/Methods') {
            let printingBuild = printingMethods;
            printingBuild.forEach(element => {
              if (element.availableMethod === methodDetails.availableMethod) {
                element.checked = true;
              }
            });
            setPrintingMethods(printingBuild);
          }
          methodsChecked.push(methodDetails.availableMethod);
        }
        setMethodsArray(methodsChecked);
        setMethods(methodsChecked.join(', '));
      }

    const handleListItemClick = (e, index) => {
        localStorage.setItem("index", index);
        history.push("/entry");
    };

    let compareRef = useRef();

    /* const filterEntries = (searchKey) => {
        const results = [];
        allEntries.forEach(entry => {
            if (entry.index.toUpperCase().includes(searchKey.toUpperCase()) || entry.project.toUpperCase().includes(searchKey.toUpperCase()) || 
            entry.elnReferences.toUpperCase().includes(searchKey.toUpperCase()) || entry.methods.toUpperCase().includes(searchKey.toUpperCase())
            || entry.hypothesis.toUpperCase().includes(searchKey.toUpperCase())|| entry.subProject.toUpperCase().includes(searchKey.toUpperCase())
            || entry.createdBy.toUpperCase().includes(searchKey.toUpperCase())) {
                results.push(entry);
            }
        });
        setEntries(results);
    };
    
    const sortEntries = (sortKey) => {
        setEntries(_.sortBy(entries, sortKey));
    }; */

    const methodList = (items) => (
        <Paper className={classes.paperList}>
          <List dense component="div" role="list">
            {items.map((value) => {
              const labelId = `transfer-list-item-${value}-label`;
    
              return (
                <Tooltip title={value.description} placement="bottom-start" arrow>
                <ListItem key={value} role="listitem" button onClick={(e) => handleCheck(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={value.checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.availableMethod}`} />
                </ListItem>
                </Tooltip>
              );
            })}
            <ListItem />
          </List>
        </Paper>
      );
    
    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <React.Fragment>
            <CssBaseline />
            <main>
                <LoadingBackdrop open={loading} />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                <Container maxWidth="xl">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    ELN index Entries Search
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                    
                    </Typography>
                    <div className={classes.heroButtons}>
                    {/* <Grid container spacing={2} justify="center">
                        <Grid item>
                        <Button variant={allEntriesButtonVariant} color="secondary" size={allEntriesButtonSize} onClick={() => loadAllEntries()}>
                            All Entries
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button variant={myEntriesButtonVariant} color="secondary" size={myEntriesButtonSize} onClick={() => loadMyEntries()}>
                            My Entries
                        </Button>
                        </Grid>
                        {/* <Grid item>
                        <Button variant={myStudentsButtonVariant} color="secondary" size={myStudentsButtonSize} onClick={() => loadMyStudents()}>
                            Entries by my PhD students
                        </Button>
                        </Grid> 
                    </Grid> */}
                    </div>
                </Container>
                </div>
                <Container maxWidth="lg">
                (Please select the tags to filter entries)
                <Grid container>
                    <Grid item><h4>Analytics</h4>
                        {methodList(analyticsMethods)}
                    </Grid>
                    <Grid item><h4>Bioinks/Materials</h4>
                        {methodList(bioinksMethods)}
                    </Grid>
                    <Grid item><h4>Cell Culture/Animals</h4>
                        {methodList(cellMethods)}
                    </Grid>
                    <Grid item><h4>Imaging/Modelling</h4>
                        {methodList(imagingMethods)}
                    </Grid>
                    <Grid item><h4>Printing/Methods</h4>
                        {methodList(printingMethods)}
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={7}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="entries_search"
                            label="Name"
                            name="entries_search"
                            onChange={(e) => {setNameSearchKey(e.target.value); }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="entries_search"
                            label="TRR225 Project (example: A01)"
                            name="entries_search"
                            onChange={(e) => {setSubProjectSearchKey(e.target.value); }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={loadAllEntries}
                        >
                            Search
                        </Button>
                    </Grid>
                    
                </Grid>
                    
                    {(entriesLoaded) ? 
                        (<>
                        
                        </>) : (<div></div>)
                    }
                    {(entries.length < 1) ? (<h5>No results</h5>) : (<div></div>)}
                <List>
                    {entries.map(entry => (
                        <ListItem button onClick={(e) => handleListItemClick(e, entry.index)} key={entry.index}>
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={entry.project}
                                secondary={`Created By: ${entry.createdBy} || TRR225 project: ${entry.subProject} || ELN reference(s): ${entry.elnReferences} || Methods/Tags: ${entry.methods}`} 
                            />
                            {/* <ListItemSecondaryAction>
                                <Button ref={compareRef} color="secondary" variant="contained" onClick={(e) => handleCompareClick(e, entry.ink_exp_id)}>
                                    {compareButtonText}
                                </Button>
                            </ListItemSecondaryAction> */}
                        </ListItem>
                    ))}
                </List>
                </Container>
            </main>
        </React.Fragment>
        )
        }
        </>
    );
}
