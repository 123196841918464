import React, { useState, useContext, useRef, useEffect } from 'react';
import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import _, { methods } from 'underscore';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { TextField } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';


const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: `url(${"/images/background.jpg"})`,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
  }));

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

const sortByInputs = [
    {
        value: 'index',
        label: 'Entry Index'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'project',
        label: 'Project'
    },
];

export default function ViewEntries() {
    const classes = useStyles();
    const { userData } = useContext(UserContext);
    const history = useHistory()

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();

    const [ allEntriesButtonVariant, setAllEntriesButtonVariant ] = useState("outlined");
    const [ myEntriesButtonVariant, setMyEntriesButtonVariant ] = useState("outlined");
    const [ myStudentsButtonVariant, setMyStudentsButtonVariant ] = useState("outlined");
    const [ allEntriesButtonSize, setAllEntriesButtonSize ] = useState();
    const [ myEntriesButtonSize, setMyEntriesButtonSize ] = useState();
    const [ myStudentsButtonSize, setMyStudentsButtonSize ] = useState();

    const [ allEntries, setAllEntries ] = useState([]);
    const [ entries, setEntries ] = useState([]);
    const [ entriesLoaded, setEntriesLoaded ] = useState(false);
    /* let searchKey = ""; */
    const [ sortBy, setSortBy ] = useState("");
  
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        loadAllEntries();
    }, []);

    const loadAllEntries = async () => {
        try {
            setLoading(true);
            const entriesRes = await axiosClient.get("entry/getAll");
            const subProjectRes = await axiosClient.get("users/getSubProjects");
            entriesRes.data.forEach(entry => {
                subProjectRes.data.forEach(user => {
                    if (user.id === entry.userId) {
                        entry.subProject = user.subProject;
                        entry.createdBy = user.firstname + ' ' + user.lastname;
                    }
                });
            });
            setEntries(entriesRes.data);
            setAllEntries(entriesRes.data);
            setEntriesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setAllEntriesButtonVariant("contained");
        setAllEntriesButtonSize("large");
        setMyEntriesButtonVariant("outlined");
        setMyEntriesButtonSize("medium");
        setMyStudentsButtonVariant("outlined");
        setMyStudentsButtonSize("medium");
    };

    const loadMyEntries = async () => {
        try {
            setLoading(true);
            const entriesRes = await axiosClient.get("entry/getMyEntries");
            const subProjectRes = await axiosClient.get("users/getSubProjects");
            entriesRes.data.forEach(entry => {
                subProjectRes.data.forEach(user => {
                    if (user.id === entry.userId) {
                        entry.subProject = user.subProject;
                        entry.createdBy = user.firstname + ' ' + user.lastname;
                    }
                });
            });
            setEntries(entriesRes.data);
            setAllEntries(entriesRes.data);
            setEntriesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setMyEntriesButtonVariant("contained");
        setMyEntriesButtonSize("large");
        setAllEntriesButtonVariant("outlined");
        setAllEntriesButtonSize("medium");
        setMyStudentsButtonVariant("outlined");
        setMyStudentsButtonSize("medium");
    }

    const loadMyStudents = async () => {
        try {
            setLoading(true);
            const entriesRes = await axiosClient.get("entry/getMyStudents");
            setEntries(entriesRes.data);
            setAllEntries(entriesRes.data);
            setEntriesLoaded(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setSeverity("error");
            err.response.data.errorMessage && setNotification(err.response.data.errorMessage);
        }
        setMyStudentsButtonVariant("contained");
        setMyStudentsButtonSize("large");
        setAllEntriesButtonVariant("outlined");
        setAllEntriesButtonSize("medium");
        setMyEntriesButtonVariant("outlined");
        setMyEntriesButtonSize("medium");
    }

    const handleListItemClick = (e, index) => {
        localStorage.setItem("index", index);
        history.push("/entry");
    };

    let compareRef = useRef();

    const filterEntries = (searchKey) => {
        const results = [];
        allEntries.forEach(entry => {
            if (entry.index.toUpperCase().includes(searchKey.toUpperCase()) || entry.project.toUpperCase().includes(searchKey.toUpperCase()) || 
            entry.elnReferences.toUpperCase().includes(searchKey.toUpperCase()) || entry.methods.toUpperCase().includes(searchKey.toUpperCase())
            || entry.hypothesis.toUpperCase().includes(searchKey.toUpperCase())|| entry.subProject.toUpperCase().includes(searchKey.toUpperCase())
            || entry.createdBy.toUpperCase().includes(searchKey.toUpperCase())) {
                results.push(entry);
            }
        });
        setEntries(results);
    };
    
    const sortEntries = (sortKey) => {
        setEntries(_.sortBy(entries, sortKey));
    };
    
    return (
        <>
        {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
        (   
            <React.Fragment>
            <CssBaseline />
            <main>
                <LoadingBackdrop open={loading} />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                <Container maxWidth="lg">
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    ELN index Entries Lookup
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                    
                    </Typography>
                    <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                        <Button variant={allEntriesButtonVariant} color="secondary" size={allEntriesButtonSize} onClick={() => loadAllEntries()}>
                            All Entries
                        </Button>
                        </Grid>
                        <Grid item>
                        <Button variant={myEntriesButtonVariant} color="secondary" size={myEntriesButtonSize} onClick={() => loadMyEntries()}>
                            My Entries
                        </Button>
                        </Grid>
                        {/* <Grid item>
                        <Button variant={myStudentsButtonVariant} color="secondary" size={myStudentsButtonSize} onClick={() => loadMyStudents()}>
                            Entries by my PhD students
                        </Button>
                        </Grid> */}
                    </Grid>
                    </div>
                </Container>
                </div>
                <Container maxWidth="lg">
                    {(entriesLoaded) ? 
                        (
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="entries_search"
                                    label="Search Entries"
                                    name="entries_search"
                                    onChange={(e) => {/* searchKey=e.target.value; */ filterEntries(e.target.value);}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    select
                                    id="entries_sort"
                                    name="entries_sort"
                                    label="Sort by"
                                    value={sortBy}
                                    onChange={(e) => {setSortBy(e.target.value); sortEntries(e.target.value);}}
                                >
                                    {sortByInputs.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        ) : (<div></div>)
                    }
                    {(entries.length < 1) ? (<h5>No results</h5>) : (<div></div>)}
                <List>
                    {entries.map(entry => (
                        <ListItem button onClick={(e) => handleListItemClick(e, entry.index)} key={entry.index}>
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={entry.project}
                                secondary={`Created By: ${entry.createdBy} || TRR225 project: ${entry.subProject} || ELN reference(s): ${entry.elnReferences} || Methods/Tags: ${entry.methods}`} 
                            />
                            {/* <ListItemSecondaryAction>
                                <Button ref={compareRef} color="secondary" variant="contained" onClick={(e) => handleCompareClick(e, entry.ink_exp_id)}>
                                    {compareButtonText}
                                </Button>
                            </ListItemSecondaryAction> */}
                        </ListItem>
                    ))}
                </List>
                </Container>
            </main>
        </React.Fragment>
        )
        }
        </>
    );
}
