import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CardActionArea } from '@material-ui/core';

import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import Footer from '../layouts/Footer';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      backgroundImage: `url(${"/images/background.jpg"})`,
      marginTop: theme.spacing(1),
    },
    card: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
      height: 0
    },
    cardContent: {
      flexGrow: 1,
      color: "white"
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
}));

const cards = [
  {
    image: "/images/Trr225_General_Information.jpeg",
    heading: "TRR225 General Information",
    description: "",
    backgroundColor: "#5a5a5a",
    forward : "/generalInformation"
  },{
    image: "/images/Trr225_Stipends.jpeg",
    heading: "TRR225 Stipends, Awards, Proposals",
    description: "",
    backgroundColor: "#5a5a5a",
    forward : "/stipends"
  },{
    image: "/images/Trr225_CI.jpeg",
    heading: "TRR225 Corporate Identity",
    description: "",
    backgroundColor: "#5a5a5a",
    forward : "/corporateIdentity"
  },{
    image: "/images/Trr225_Students.jpeg",
    heading: "TRR225 PhD Students",
    description: "",
    backgroundColor: "#5a5a5a",
    forward : "/phdStudents"
  },
];

export default function InternalDocuments() {
    const classes = useStyles();
    
    const { userData } = useContext(UserContext);
    const history = useHistory();
  
    const forward = (page) => {
      history.push(page);
    }

    return (
      <>
      {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
      (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Container className={classes.cardGrid} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.heading} xs={12} sm={3} lg={3}>
                <Card className={classes.card} style={{backgroundColor: card.backgroundColor}} onClick={() => forward(card.forward)}>
                  <CardActionArea>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title={card.heading}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>
                      {card.description}
                    </Typography>
                  </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
    )
    }
    </>
  )
}
