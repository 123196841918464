import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { CardActionArea } from '@material-ui/core';
import { Box } from '@material-ui/core';

import UserContext from "../../context/UserContext";
import { useHistory } from 'react-router-dom';
import Footer from '../layouts/Footer';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      marginTop: theme.spacing(1),
    },
    card: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
      height: 0
    },
    cardContent: {
      flexGrow: 1,
      color: "white"
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    boxLeft : {
      marginTop : theme.spacing(1),
      marginLeft : theme.spacing(10),
      alignItems: 'center'
    },
    bigHeading : {
        marginTop : theme.spacing(4),
        fontSize : 48,
        fontWeight : 600
    },
}));

const cards = [
    {
        image: "/images/eln_up.jpg",
        heading: "Make a new entry",
        description: "",
        backgroundColor: "#35a2bb",
        forward: "/newEntry"
    },
    {
        image: "/images/eln_ch.jpg",
        heading: "Update your entry",
        description: "",
        backgroundColor: "#35a2bb",
        forward : "/updateEntryPage"
    },
    {
        image: "/images/eln_search.jpg",
        heading: "ELN Index Lookup",
        description: "",
        backgroundColor: "#35a2bb",
        forward : "/viewEntries"
    },
];

const adminCards = [
  {
    image: "/images/eln_search.jpg",
    heading: "View Methods/Tags",
    description: "",
    backgroundColor: "#5a5a5a",
    forward : "/viewMethods"
  },
  {
    image: "/images/eln_search.jpg",
    heading: "User Management",
    description: "",
    backgroundColor: "#5a5a5a",
    forward : "/userManagement"
  },
  {
    image: "/images/eln_search.jpg",
    heading: "Entries Search",
    description: "",
    backgroundColor: "#5a5a5a",
    forward : "/entriesSearch"
  },
  {
    image: "/images/Internal_Documents.jpeg",
    heading: "Internal Documents",
    description: "",
    backgroundColor: "#5a5a5a",
    forward : "/internalDocuments"
  },
];

export default function Dashboard() {
    const classes = useStyles();
    
    const { userData } = useContext(UserContext);
    const history = useHistory();
  
    const forward = (page) => {
      history.push(page);
    }

    return (
      <>
      {
        (!userData.token) ?
        (<div>You are not authorised to access this section. Please login.</div>) :
      (
    <React.Fragment>
      <CssBaseline />
      <main style={{backgroundImage: `url(${"/images/elni-bg.jpg"})`}}>
        <Container component="main">
          <CssBaseline />
          <div className={classes.paper}>
                  <Box display="flex">
                      <Box p={4} className={classes.boxLeft}>
                          <div className={classes.bigHeading}>Welcome to Electronic Lab Notebook Index</div>
                      </Box>
                  </Box>
          </div>
        </Container>
        
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.heading} xs={12} sm={6} md={4}>
                <Card className={classes.card} style={{backgroundColor: card.backgroundColor}} onClick={() => forward(card.forward)}>
                  <CardActionArea>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title={card.heading}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>
                      {card.description}
                    </Typography>
                  </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          {
            (userData.role==="admin") ?
            (<Grid container spacing={4}>
              {adminCards.map((card) => (
                <Grid item key={card.heading} xs={12} sm={6} md={4}>
                  <Card className={classes.card} style={{backgroundColor: card.backgroundColor}} onClick={() => forward(card.forward)}>
                    <CardActionArea>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.image}
                      title={card.heading}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.heading}
                      </Typography>
                      <Typography>
                        {card.description}
                      </Typography>
                    </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>) : (<div></div>)
          }
        </Container>
      </main>
    </React.Fragment>
    )
    }
    </>
  )
}
