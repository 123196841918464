import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ClassIcon from '@material-ui/icons/Class';
import { useHistory } from 'react-router-dom';
import UserContext from "../../context/UserContext";
import NotificationSnackbar from "../feedback/NotificationSnackbar";
import LoadingBackdrop from '../feedback/LoadingBackdrop';
import axios from "axios";

export default function AuthOptions() {
    const { userData, setUserData } = useContext(UserContext);

    const [ submitDisabled, setSubmitDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const [ notification, setNotification ] = useState();
    const [ severity, setSeverity ] = useState();
    
    const history = useHistory();

    const axiosClient = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
            "x-auth-token" : userData.token
        }
    });

    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time));
    }

    const login = (e) => {
        history.push("/login");
    }

    const addMethod = (e) => {
        history.push("/addMethod");
    }

    const changePassword = (e) => {
        history.push("/changePassword");
    }
    
    const logout = async () => {
        try {
            setLoading(true);
            setSubmitDisabled(true);
            await axiosClient.post("users/logout");
            sleep(3000).then(()=>{
            setLoading(false);
            });
        } catch (error) {
           console.log(error.message);
        }
        setUserData({
            token: undefined,
            user: undefined,
            firstname: undefined,
            role: undefined
        });
        localStorage.setItem("auth-token", "");
        history.push("/login")
    };
    
    return (
        <nav>
            {
                userData.user ? (<>
                <Button variant="contained" color="default" onClick={addMethod} startIcon={<ClassIcon />}>Add Method/Tag</Button>
                <Button variant="contained" color="default" onClick={changePassword} startIcon={<LockIcon />}>Change Password</Button>
                <Button variant="contained" color="default" onClick={logout} startIcon={<ExitToAppIcon disabled={submitDisabled} />}>Logout</Button>
                </>) : (
            <>
            <Button variant="contained" color="secondary" onClick={login} startIcon={<LockOpenIcon />}>Login</Button>
            </>
                )}
        </nav>
    );
}