import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import UserContext from "../../context/UserContext";
import axios from "axios";
import { Button } from '@material-ui/core';
import LoadingBackdrop from '../feedback/LoadingBackdrop';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#005896",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 400,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Entry(props) {
  const classes = useStyles();
  const { userData } = useContext(UserContext);

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers: {
        "x-auth-token" : userData.token
    }
    });

  const [ entry, setEntry ] = useState();
  const [ index, setIndex ] = useState();
  const [ loading, setLoading ] = useState(true);
  const [ uploadedBy, setUploadedBy ] = useState();

  useEffect(() => { 
    const loadEntry = async () => {  
        if (!entry && localStorage.getItem(props.index)) {
            try {
                setIndex(localStorage.getItem(props.index));
                const entryRes = await axiosClient.get("/entry/getEntry", {params: {index: localStorage.getItem(props.index)}});
                setEntry(entryRes.data);
                const uploadedByRes = await axiosClient.get("/users/getUserById", {params: {userIdId: entryRes.data.userId}});
                setUploadedBy(uploadedByRes.data);
                setLoading(false);
            } catch (error) {
                
            }
        }
    };
    window.scroll(0,0);
    loadEntry();
  }, []);

  return (entry ? (
    <>      <LoadingBackdrop open={loading} />
            <Grid item md={12}>
            {/* {uploadedBy ? (<h4>Created by: {uploadedBy.firstname} {uploadedBy.lastname}</h4>) : (<></>)} */}
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table" >
                <TableBody>
                    <StyledTableRow key="created_by">
                        <StyledTableCell component="th" scope="row">
                          Created by:
                        </StyledTableCell>
                        <StyledTableCell >{uploadedBy && entry ? <a href={"mailto:" + uploadedBy.email}>{uploadedBy.firstname} {uploadedBy.lastname}</a>  : "----"} (Click to contact)</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="created_by">
                        <StyledTableCell component="th" scope="row">
                          TRR225 project:
                        </StyledTableCell>
                        <StyledTableCell >{uploadedBy && entry ? uploadedBy.subProject  : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="print_exp_id">
                        <StyledTableCell component="th" scope="row">
                            Index:
                        </StyledTableCell>
                        <StyledTableCell >{entry ? entry.index : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="phd_id">
                        <StyledTableCell component="th" scope="row">
                            Project Title:
                        </StyledTableCell>
                        <StyledTableCell >{entry ? entry.project : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="lab_id">
                        <StyledTableCell component="th" scope="row">
                            Hypothesis:
                        </StyledTableCell>
                        <StyledTableCell >{entry ? entry.hypothesis : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="date">
                        <StyledTableCell component="th" scope="row">
                            Date:
                        </StyledTableCell>
                        <StyledTableCell >{entry ? entry.date : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="bioink_id">
                        <StyledTableCell component="th" scope="row">
                            Methods/Tags:
                        </StyledTableCell>
                        <StyledTableCell >{entry ? entry.methods : "----"}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="compos_text">
                        <StyledTableCell component="th" scope="row">
                            ELN References:
                        </StyledTableCell>
                        <StyledTableCell >{entry ? entry.elnReferences : "----"}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            </TableContainer>
            <Divider />
            <div></div>
            </Grid>
    </>) : (<></>)
  );
}
